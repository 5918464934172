import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as StationsActions from './stations.actions';
import { AppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as StationsSelectors from './stations.selectors';
import { StationModel } from '../backend/stations/model/station.model';
import { StationStatusModel } from '../backend/stations/model/station-status.model';

@Injectable()
export class StationsStoreService {
  constructor(private store: Store<AppState>) {}

  public requestStations() {
    this.store.dispatch(StationsActions.RequestStationsAction());
  }

  public getAllStations(): Observable<StationModel[]> {
    return this.store.pipe(
      select((state) => StationsSelectors.getAllStations(state))
    );
  }

  public requestUpdateStations(stations: StationModel[]) {
    this.store.dispatch(
      StationsActions.UpdateStationsRequestAction({ payload: { stations } })
    );
  }

  public requestStationsStatuses() {
    this.store.dispatch(StationsActions.RequestStationsStatusesAction());
  }

  public getAllStationsStatuses(): Observable<StationStatusModel[]> {
    return this.store.pipe(
      select((state) => StationsSelectors.getAllStationsStatuses(state))
    );
  }

  public requestUpdateStationsStatuses(stationsStatuses: StationStatusModel[]) {
    this.store.dispatch(
      StationsActions.UpdateStationsStatusesRequestAction({
        payload: { stationsStatuses },
      })
    );
  }
}
