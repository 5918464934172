import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CrateModel } from './model/crate.model';
import { CratePanelModel } from './model/crate-panel.model';

@Injectable()
export class CratesBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    // 'content-type': 'application/json',
    //  'enctype': 'multipart/form-data',
    // 'Auth-Token':
    //   '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  fetchUpdateCrates(crates: CrateModel[]): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates`;
    return this.http.post<any[]>(url, { crates }, this.getOptions());
  }

  fetchCrates(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  fetchAddCratePanel(
    crateData: CratePanelModel
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates/add-panel`;
    return this.http.post<any[]>(url, { crateData }, this.getOptions());
  }

  fetchRemoveCratePanel(cratePanelId: string): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates/remove-panel`;
    return this.http.post<any[]>(url, { cratePanelId }, this.getOptions());
  }

  fetchCratePanels(projectIds: string[]): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates/cratePanelsByProjects`;
    return this.http.post<any[]>(url, { projectIds }, this.getOptions());
  }

  fetchMiscItems(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates/get-misc-item`;
    return this.http.post<any[]>(url, {}, this.getOptions());
  }

  fetchAddMiscItem(item: any): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates/add-misc-item`;
    return this.http.post<any[]>(url, { item }, this.getOptions());
  }

  fetchCrateItemsByProjectIds(
    projectIds: string[]
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates/get-items-by-projects`;
    return this.http.post<any[]>(url, { projectIds }, this.getOptions());
  }

  fetchAddItemToCrate(item: any): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates/add-crate-item`;
    return this.http.post<any[]>(url, { item }, this.getOptions());
  }

  fetchRemoveCrateItem(itemId: string): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/crates/remove-item`;
    return this.http.post<any[]>(url, { itemId }, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
