import { createSelector } from '@ngrx/store';
import { getPanelsState } from '../store/store.selectors';
import { PanelsStateInterface, selectAll } from './panels.state';

export const getAllPanels = createSelector(
  getPanelsState,
  (state: PanelsStateInterface) => selectAll(state)
);

export const selectPanel = createSelector(
  getAllPanels,
  (panels, panelId: string) => {
    console.log(panelId);
    console.log(panels);
    return panels.find((panel) => panel.SFMPanelID === panelId);
  }
);

export const getAllPanelsStatuses = createSelector(
  getPanelsState,
  (state: PanelsStateInterface) => state.panelStatuses
);
