import { createSelector } from '@ngrx/store';
import { getAuthState } from '../../store/store.selectors';
import { AuthStateInterface } from '../auth.state';
import { RolesStateInterface, selectAll } from './roles.state';

export const getRolesState = createSelector(
  getAuthState,
  (state: AuthStateInterface) => state.roles
);

export const getAllRoles = createSelector(
  getRolesState,
  (state: RolesStateInterface) => selectAll(state)
);
