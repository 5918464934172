import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import * as WorkOrdersActions from './work-orders.actions';
import { WorkOrdersService } from '../backend/work-orders/work-orders.service';
import { LoadingController } from '@ionic/angular';
import { AddShippingListReceiveAction } from './work-orders.actions';

@Injectable()
export class WorkOrdersEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private workOrdersService: WorkOrdersService,
    private loadingCtrl: LoadingController
  ) {}

  @Effect()
  OnGetAllJobsRequest$ = this.actions$.pipe(
    ofType(WorkOrdersActions.RequestAllWorkOrdersAction),
    throttleTime(15000),
    // tap(x => console.log(x)),
    switchMap(() =>
      from(this.workOrdersService.getAllWorkOrders()).pipe(
        mergeMap((data: any) => [
          WorkOrdersActions.ReceiveAllWorkOrdersAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestSyncWorkOrders$ = this.actions$.pipe(
    ofType(WorkOrdersActions.RequestSyncAction),
    map((action) => action.payload.projects),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.workOrdersService.syncWorkOrders(requestData)).pipe(
        tap(() => this.loadingCtrl.dismiss()),
        mergeMap((data: any) => [
          WorkOrdersActions.ReceiveSyncAction({ payload: data }),
          WorkOrdersActions.RequestAllWorkOrdersAction(),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnAddShippingList$ = this.actions$.pipe(
    ofType(WorkOrdersActions.AddShippingListAction),
    map((action) => action.payload.shippingList),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.workOrdersService.addShippingList(requestData)).pipe(
        mergeMap((data: any) => [
          AddShippingListReceiveAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestShippingLists$ = this.actions$.pipe(
    ofType(WorkOrdersActions.RequestShippingListsAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.workOrdersService.getAllShippingLists()).pipe(
        mergeMap((data: any) => [
          WorkOrdersActions.ReceiveShippingListsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );
}
