import * as PanelsActions from './panels.actions';
import {
  adapterPanels,
  initPanelsstate,
  PanelsStateInterface,
} from './panels.state';

export function PanelsReducer(
  state = initPanelsstate,
  action
): PanelsStateInterface {
  switch (action.type) {
    case PanelsActions.ReceivePanelsAction.type: {
      const payload = action.payload.data;
      return adapterPanels.upsertMany(payload, { ...state }); // add or update time jobs
    }
    case PanelsActions.ReceivePanelByIdAction.type: {
      const payload = action.payload.data;
      if (!payload) {
        return state;
      }

      return adapterPanels.upsertOne(payload, { ...state }); // add or update time jobs
    }
    case PanelsActions.PanelStatusReceiveAction.type: {
      const payload = action.payload.data;
      return { ...state, panelStatuses: payload };
    }
    default:
      return state;
  }
}
