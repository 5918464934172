import * as RolesActions from './roles.actions';
import {
  initRoleState,
  adapterRoles,
  RolesStateInterface,
} from './roles.state';

export function RolesReducer(
  state = initRoleState,
  action
): RolesStateInterface {
  switch (action.type) {
    case RolesActions.ReceiveRolesAction.type: {
      const payload = action.payload.data;
      return adapterRoles.upsertMany(payload, { ...state });
    }

    default:
      return state;
  }
}
