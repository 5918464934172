import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as CratesActions from './crates.actions';
import { AppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as CratesSelectors from './crates.selectors';
import { CrateModel } from '../backend/crates/model/crate.model';
import { CratePanelModel } from '../backend/crates/model/crate-panel.model';

@Injectable()
export class CratesStoreService {
  constructor(private store: Store<AppState>) {}

  public getAllCrates(): Observable<CrateModel[]> {
    return this.store.pipe(
      select((state) => CratesSelectors.getAllCrates(state))
    );
  }

  public requestAllCrates() {
    this.store.dispatch(CratesActions.RequestCratesAction());
  }

  public requestUpdateCrates(crates: CrateModel[]) {
    this.store.dispatch(
      CratesActions.UpdateCratesRequestAction({ payload: { crates } })
    );
  }

  public requestAddPanelToCrate(crateData: CratePanelModel) {
    this.store.dispatch(
      CratesActions.AddPanelRequestAction({ payload: { crateData } })
    );
  }

  public requestAllCratePanels(projectIds: string[]) {
    this.store.dispatch(
      CratesActions.RequestCratePanelsAction({ payload: { projectIds } })
    );
  }

  public getAllCratePanels(): Observable<CratePanelModel[]> {
    return this.store.pipe(
      select((state) => CratesSelectors.getAllCratePanels(state))
    );
  }

  public requestRemovePanelFromCrate(id: string) {
    this.store.dispatch(
      CratesActions.RemovePanelRequestAction({ payload: { id } })
    );
  }

  public requestMiscItems() {
    this.store.dispatch(CratesActions.RequestMiscItemsAction());
  }

  public requestAddUpdateMiscItem(item: any) {
    this.store.dispatch(
      CratesActions.RequestAddUpdateMiscItemAction({ payload: { item } })
    );
  }

  public getMiscItems(): Observable<any[]> {
    return this.store.pipe(
      select((state) => CratesSelectors.getMiscItems(state))
    );
  }

  public requestAddItemToCrate(item: any) {
    this.store.dispatch(
      CratesActions.RequestAddItemToCrateAction({ payload: { item } })
    );
  }

  public requestCrateItems(projectIds: string[]) {
    this.store.dispatch(
      CratesActions.RequestCrateItemsActions({ payload: { projectIds } })
    );
  }

  public getCrateItems(): Observable<any[]> {
    return this.store.pipe(
      select((state) => CratesSelectors.getCrateItems(state))
    );
  }

  public requestRemoveItemFromCrate(id: string) {
    this.store.dispatch(
      CratesActions.RemoveItemRequestAction({ payload: { id } })
    );
  }
}
