import { createAction, props } from '@ngrx/store';
import { RoleModel } from '../../backend/auth/model/role.model';

export const RequestRolesAction = createAction('[Roles] Request All');

export const UpdateRolesRequestAction = createAction(
  '[Roles] Update',
  props<{ payload: { roles: RoleModel[] } }>()
);

export const ReceiveRolesAction = createAction(
  '[Roles] Receive',
  props<{ payload: { roles: RoleModel[] } }>()
);
