import { createSelector } from '@ngrx/store';
import { getWorkOrdersState } from '../store/store.selectors';
import { WorkOrdersStateInterface, selectAll } from './work-orders.state';

export const getAllWorkOrders = createSelector(
  getWorkOrdersState,
  (state: WorkOrdersStateInterface) => selectAll(state)
);

export const getAllShippingLists = createSelector(
  getWorkOrdersState,
  (state: WorkOrdersStateInterface) => state.shippingLists
);
