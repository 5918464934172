import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TapeModel } from '../backend/tapes/model/tape.model';

export interface TapesStateInterface extends EntityState<TapeModel> {
  // additional entity state properties
}

export function selectTapeId(a: TapeModel): string {
  return a.NID;
}

export function sortByNestNumber(a: TapeModel, b: TapeModel): number {
  if (a.NestNumber > b.NestNumber) {
    return 1;
  }

  if (a.NestNumber < b.NestNumber) {
    return -1;
  }

  return 0;
}

export const adapterTapes: EntityAdapter<TapeModel> =
  createEntityAdapter<TapeModel>({
    selectId: selectTapeId,
    sortComparer: sortByNestNumber,
  });

export const initTapesState: TapesStateInterface = adapterTapes.getInitialState(
  {}
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapterTapes.getSelectors();
