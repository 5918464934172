import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RoleModel } from './model/role.model';
import { AuthBackend } from './auth.backend';
import { UserModel } from './model/user.model';

@Injectable()
export class AuthService extends BaseService {
  constructor(private authBackend: AuthBackend) {
    super();
  }

  updateRoles(roles: RoleModel[]): Observable<any> {
    return this.authBackend
      .fetchUpdateRoles(roles)
      .pipe(map((response) => response.body));
  }

  getRoles(): Observable<any> {
    return this.authBackend.fetchRoles().pipe(map((response) => response.body));
  }

  updateUser(user: UserModel): Observable<any> {
    return this.authBackend.fetchUpdateUser(user).pipe(
      map((response) => response.body),
      tap((x) => console.log(x))
    );
  }

  getAuthUser(userId: string): Observable<any> {
    return this.authBackend.fetchAuthUser(userId).pipe(
      map((response) => response.body),
      tap((x) => console.log(x))
    );
  }

  login(userCredentials: {
    username: string;
    password: string;
  }): Observable<any> {
    return this.authBackend.fetchLogin(userCredentials).pipe(
      map((response) => response.body),
      tap((x) => console.log(x))
    );
  }

  getUsers(): Observable<any> {
    return this.authBackend.fetchUsers().pipe(map((response) => response.body));
  }
}
