import * as TapesActions from './tapes.actions';
import {
  adapterTapes,
  initTapesState,
  TapesStateInterface,
} from './tapes.state';

export function TapesReducer(
  state = initTapesState,
  action
): TapesStateInterface {
  switch (action.type) {
    case TapesActions.ReceiveTapesAction.type: {
      const payload = action.payload.data;
      // console.log(payload);
      return adapterTapes.upsertMany(payload, { ...state }); // add or update time jobs
    }
    default:
      return state;
  }
}
