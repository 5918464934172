import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as PanelsActions from './panels.actions';
import { AppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as PanelsSelectors from './panels.selectors';
import { PanelModel } from '../backend/panels/model/panel.model';

@Injectable()
export class PanelsStoreService {
  constructor(private store: Store<AppState>) {}

  public requestPanelsByTape(tape: string) {
    this.store.dispatch(
      PanelsActions.RequestPanelsByTapeAction({ payload: { tape } })
    );
  }

  public requestPanelsByProjectIds(projectIds: string[]) {
    this.store.dispatch(
      PanelsActions.RequestPanelsByProjectIdsAction({ payload: { projectIds } })
    );
  }

  public requestPanelsByCrate(crateId: string) {
    this.store.dispatch(
      PanelsActions.RequestPanelsByCrateAction({ payload: { crateId } })
    );
  }

  public requestPanelById(panelId: string) {
    this.store.dispatch(
      PanelsActions.RequestPanelByIdAction({ payload: { panelId } })
    );
  }

  public requestPanelsUpdate(panels: PanelModel[]) {
    this.store.dispatch(
      PanelsActions.RequestPanelsUpdateAction({ payload: { panels } })
    );
  }

  public requestPrintPanel(panel: {
    panelId: number;
    workOrderNumber: string;
    panelTag: string;
    panelProject: string;
    panelRelease: string;
    panelQuantity: string;
    panelColor: string;
  }) {
    this.store.dispatch(
      PanelsActions.PanelPrintRequestAction({ payload: { panelData: panel } })
    );
  }

  public requestPrintPanels(
    panels: {
      panelId: number;
      workOrderNumber: string;
      panelTag: string;
      panelProject: string;
      panelRelease: string;
      panelQuantity: string;
      panelColor: string;
    }[]
  ) {
    this.store.dispatch(
      PanelsActions.PanelsPrintRequestAction({
        payload: { panelsData: panels },
      })
    );
  }

  public getAllPanels(): Observable<PanelModel[]> {
    return this.store.pipe(
      select((state) => PanelsSelectors.getAllPanels(state))
    );
  }

  public updatePanelStatus(panelData: {
    SFMPanelID: number;
    StatusId: number;
    Project: string;
    EmployeeId: string;
  }) {
    this.store.dispatch(
      PanelsActions.PanelStatusUpdateRequestAction({ payload: { panelData } })
    );
  }

  public requestPanelsStatusesByProjectIds(projectIds: string[]) {
    this.store.dispatch(
      PanelsActions.PanelStatusRequestAction({ payload: { projectIds } })
    );
  }

  public getAllPanelStatuses(): Observable<any[]> {
    return this.store.pipe(
      select((state) => PanelsSelectors.getAllPanelsStatuses(state))
    );
  }

  public getPanelById(panelId: string): Observable<PanelModel> {
    return this.store.pipe(
      select((state) => PanelsSelectors.selectPanel(state, panelId))
    );
  }
}
