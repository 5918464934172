import { ErrorModel } from './models/error.model';

export interface ErrorStateInterface {
  // additional entity state properties
  latestError: ErrorModel;
}

export const initErrorState: ErrorStateInterface = {
  latestError: null,
};
