import { createSelector } from '@ngrx/store';
import { getAuthState } from '../../store/store.selectors';
import { AuthStateInterface } from '../auth.state';
import { selectAll, UserStateInterface } from './user.state';

export const getUserState = createSelector(
  getAuthState,
  (state: AuthStateInterface) => state.user
);

export const getAllUsers = createSelector(
  getUserState,
  (state: UserStateInterface) => selectAll(state)
);

export const getActiveUser = createSelector(
  getUserState,
  (state: UserStateInterface) => state.activeUser
);
