import { ActionReducerMap, combineReducers, MetaReducer } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as fromWorkOrders from '../work-orders/work-orders.reducer';
import * as fromTapes from '../tapes/tapes.reducer';
import * as fromPanels from '../panels/panels.reducer';
import * as fromStations from '../stations/stations.reducer';
import * as fromPending from '../pending/pending.reducer';
import * as fromCrates from '../crates/crates.reducer';
import * as fromAuth from '../auth/auth.reducer';
import * as fromError from '../error/error.reducer';
import { routerReducer } from '@ngrx/router-store';
import { AppState } from './store.state';
import * as AuthSessionActions from '../auth/user/user.actions';

export const AppReducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  workOrders: fromWorkOrders.WorkOrdersReducer,
  tapes: fromTapes.TapesReducer,
  panels: fromPanels.PanelsReducer,
  stations: fromStations.StationsReducer,
  pending: fromPending.PendingReducer,
  crates: fromCrates.CratesReducer,
  auth: fromAuth.reducers,
  error: fromError.ErrorReducer,
};

export const APP_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>(
  'App Reducers'
);

// Can be used in the future if we want to sync with local storage
export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [clearState]
  : [clearState];

export function clearState(reducer) {
  return (state, action) => {
    if (action.type === AuthSessionActions.LogoutRequestAction.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
