import * as CratesActions from './crates.actions';
import {
  adapterCrates,
  CratesStateInterface,
  initCrateState,
} from './crates.state';

export function CratesReducer(
  state = initCrateState,
  action
): CratesStateInterface {
  switch (action.type) {
    case CratesActions.CratesReceiveAction.type: {
      const payload = action.payload.data;
      return adapterCrates.upsertMany(payload, { ...state });
    }

    case CratesActions.ReceiveCratePanelsAction.type: {
      const payload = action.payload.data;
      return {
        ...state,
        cratePanels: payload,
      };
    }

    case CratesActions.RemovePanelReceiveAction.type: {
      const payload = action.payload.data;
      return {
        ...state,
        cratePanels: state.cratePanels.filter(
          (cp) => cp.SFMPanelID === payload
        ),
      };
    }

    case CratesActions.ReceiveMiscItemsAction.type: {
      const payload = action.payload.data;
      return {
        ...state,
        miscItems: payload,
      };
    }

    case CratesActions.ReceiveCrateItemsActions.type: {
      const payload = action.payload.data;
      return {
        ...state,
        crateItems: payload,
      };
    }

    case CratesActions.RemoveItemReceiveAction.type: {
      const payload = action.payload;
      console.log(payload);
      const updatedItems = state.crateItems.filter(
        (i) => i.Id.toString() !== payload.toString()
      );
      console.log(updatedItems);
      return {
        ...state,
        crateItems: updatedItems,
      };
    }

    default:
      return state;
  }
}
