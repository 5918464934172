import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserModel } from '../../core/backend/auth/model/user.model';
import { Subject } from 'rxjs';
import {CratesStoreService} from "../../core/crates/crates.service";
import {filter, takeUntil, tap} from "rxjs/operators";
import {CrateModel} from "../../core/backend/crates/model/crate.model";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-update-panel-status',
  templateUrl: './update-panel-status.component.html',
  styleUrls: ['./update-panel-status.component.scss'],
})
export class UpdatePanelStatusComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  item: any;
  ngUnsubscribe = new Subject();
  activeCrates: CrateModel[] = [];
  crateForm: FormGroup;
  // 17 ship to paint
  // 23 ship to customer
  shippingStatuses = [17, 23];
  activeCratePanel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatePanelStatusComponent>,
    private cratesStoreService: CratesStoreService,
  ) {
    if (data) {
      console.log(data);
      this.item = data;
      this.shippingStationsHandler();
      this.panelCratesHandler();
    }

    this.crateForm = new FormGroup({
      CrateId: new FormControl(this.activeCrates.length === 0 ? 'default' : ''),
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  shippingStationsHandler() {
    this.cratesStoreService.requestAllCrates();
    this.cratesStoreService.getAllCrates().pipe(
        takeUntil(this.ngUnsubscribe)
    ).subscribe(crates => {
      const allProjectCrates = crates.filter(crate => crate.Project === this.item.project);
      this.activeCrates = this.item.newStatus.Id === 23 ? allProjectCrates.filter(crate => crate.ShipLocation === 0) :
          allProjectCrates.filter(crate => crate.ShipLocation === 1);
    });
  }

  panelCratesHandler() {
    this.cratesStoreService.requestAllCratePanels([this.item.project]);
    this.cratesStoreService.getAllCratePanels().pipe(
        filter(data => data?.length > 0),
        takeUntil(this.ngUnsubscribe)
    ).subscribe(cratePanels => {
      this.activeCratePanel = cratePanels.find(crate => crate.SFMPanelID === this.item.panelId);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeDialog() {
    // this.dialog.closeAll();
  }

  compareWith(o1: UserModel, o2: UserModel) {
    return o1 && o2 ? o1.Id === o2.Id : o1 === o2;
  }

  saveChanges() {
    // this means we are changing the status to "ship to customer" or "ship to paint"
    if (this.shippingStatuses.includes(this.item.newStatus.Id)) {
      this.cratesStoreService.requestAddPanelToCrate({
        Id: null,
        SFMPanelID: Number(this.item.panelId),
        CrateId: this.crateForm.get('CrateId').value,
        Project: this.item.project,
        EmployeeId: Number(localStorage.getItem('userId')),
        createdAt: null,
        updatedAt: null,
      });
    }

    if (this.shippingStatuses.includes(this.item.currentStatus?.Id)) {
      if (this.activeCratePanel) {
        this.cratesStoreService.requestRemovePanelFromCrate(this.activeCratePanel?.SFMPanelID);
      }
    }
    this.crateForm.reset();
    this.dialogRef.close({status: 'delete'});
  }
}
