import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import * as RolesActions from './roles.actions';
import { AuthService } from '../../backend/auth/auth.service';

@Injectable()
export class RolesEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService
  ) {}

  @Effect({ dispatch: false })
  OnUpsertRoless = this.actions$.pipe(
    ofType(RolesActions.UpdateRolesRequestAction),
    map((action) => action.payload.roles),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.authService.updateRoles(requestData)).pipe(
        mergeMap((data: any) => [
          RolesActions.ReceiveRolesAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestRoles$ = this.actions$.pipe(
    ofType(RolesActions.RequestRolesAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.authService.getRoles()).pipe(
        mergeMap((data: any) => [
          RolesActions.ReceiveRolesAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );
}
