import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CrateModel } from '../backend/crates/model/crate.model';
import { CratePanelModel } from '../backend/crates/model/crate-panel.model';

export interface CratesStateInterface extends EntityState<CrateModel> {
  // additional entity state properties
  cratePanels: CratePanelModel[];
  miscItems: any[];
  crateItems: any[];
}

export function selectStationId(a: CrateModel): string {
  return a.Id.toString();
}

export function sortById(a: CrateModel, b: CrateModel): number {
  if (a.Id > b.Id) {
    return 1;
  }

  if (a.Id < b.Id) {
    return -1;
  }

  return 0;
}

export const adapterCrates: EntityAdapter<CrateModel> =
  createEntityAdapter<CrateModel>({
    selectId: selectStationId,
    sortComparer: sortById,
  });

export const initCrateState: CratesStateInterface =
  adapterCrates.getInitialState({
    cratePanels: null,
    miscItems: null,
    crateItems: null,
  });

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapterCrates.getSelectors();
