import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { UserModel } from '../../core/backend/auth/model/user.model';
import { RoleModel } from '../../core/backend/auth/model/role.model';
import { IonSelect, LoadingController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { WorkOrdersService } from '../../core/backend/work-orders/work-orders.service';
import { takeUntil } from 'rxjs/operators';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-sync-options',
  templateUrl: './sync-options.component.html',
  styleUrls: ['./sync-options.component.scss'],
})
export class SyncOptionsComponent implements OnInit, AfterViewInit, OnDestroy {
  ngUnsubscribe = new Subject();
  typesOfShoes: string[] = [
    'Boots',
    'Clogs',
    'Loafers',
    'Moccasins',
    'Sneakers',
  ];
  projectData: any[] = [];
  loading = true;
  selectedProjects;

  @ViewChild('projects') projectsSelect: MatSelectionList;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SyncOptionsComponent>,
    private workOrdersService: WorkOrdersService
  ) {}

  ngOnInit() {
    this.workOrdersService
      .syncCheck()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        const projects = [...new Set(response.data.map((p) => p.Project))];

        for (const project of projects) {
          const projectPanels = response.data.filter(
            (panel) => panel.Project === project
          );

          let totalPanelsMissingNesting = 0;
          projectPanels.forEach(panel => {
            if (panel.NestNumber === null || panel.NestNumber === '') {
              totalPanelsMissingNesting += 1;
            }
          });
          this.projectData.push({
            project,
            totalPanels: projectPanels.length,
            totalMissingNesting: totalPanelsMissingNesting
          });
        }
        this.loading = false;
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  saveChanges() {
    this.dialogRef.close(this.selectedProjects);
  }
}
