import { createSelector } from '@ngrx/store';
import { getStationsState } from '../store/store.selectors';
import { selectAll, StationsStateInterface } from './stations.state';

export const getAllStations = createSelector(
  getStationsState,
  (state: StationsStateInterface) => selectAll(state)
);

export const getAllStationsStatuses = createSelector(
  getStationsState,
  (state: StationsStateInterface) => state.stationsStatuses
);
