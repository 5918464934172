import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { StationModel } from '../../core/backend/stations/model/station.model';

@Component({
  selector: 'app-station-status',
  templateUrl: './station-status.component.html',
  styleUrls: ['./station-status.component.scss'],
})
export class StationStatusComponent implements OnInit {
  station: StationModel;
  stationStatusForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { station: StationModel },
    public dialogRef: MatDialogRef<StationStatusComponent>
  ) {
    if (data) {
      this.station = data.station;
    }

    this.stationStatusForm = new FormGroup({
      Id: new FormControl(null),
      Name: new FormControl(''),
      Active: new FormControl(true),
      StationId: new FormControl(this.station?.Id ? this.station.Id : ''),
    });
  }

  ngOnInit() {}

  closeDialog() {
    // this.dialog.closeAll();
  }

  saveChanges() {
    console.log(this.stationStatusForm.value);
    this.dialogRef.close(this.stationStatusForm.value);
  }

  // onIconPickerSelect(icon: string): void {
  //   this.stationStatusForm.patchValue({
  //     Icon: icon
  //   });
  // }
}
