import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { UserModel } from './model/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthAmplifyService {
  private headers = new HttpHeaders({
    'content-type': 'application/json',
  });

  constructor(private http: HttpClient) {}

  async signUp(createdUser: UserModel) {
    // console.log(createdUser);
    // try {
    //   const { user } = await Auth.signUp({
    //     username: `${createdUser.Email}`,
    //     password: 'kanalco@123',
    //     attributes: {
    //       email: `${createdUser.Email}`,
    //       'custom:userId': `${createdUser.Id}`
    //     }
    //   });
    //   console.log(user);
    // } catch (error) {
    //   console.log('error signing up:', error);
    // }
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.headers,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
