import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TapesBackend } from './tapes.backend';

@Injectable()
export class TapesService extends BaseService {
  constructor(private tapesBackend: TapesBackend) {
    super();
  }

  getTapesByProject(project: string): Observable<any> {
    return this.tapesBackend
      .fetchTapesByProject(project)
      .pipe(map((response) => response.body));
  }

  getTapes(): Observable<any> {
    return this.tapesBackend
      .fetchTapes()
      .pipe(map((response) => response.body));
  }
}
