import { adapterUser, initUserState, UserStateInterface } from './user.state';
import * as UserActions from './user.actions';

export function UserReducer(state = initUserState, action): UserStateInterface {
  switch (action.type) {
    case UserActions.ReceiveUsersAction.type: {
      const payload = action.payload.data;
      return adapterUser.upsertMany(payload, { ...state });
    }

    case UserActions.AuthUserReceiveAction.type: {
      const payload = action.payload.data;
      return {
        ...state,
        activeUser: payload,
      };
    }

    case UserActions.LoginReceiveAction.type: {
      const payload = action.payload.data;
      return {
        ...state,
        activeUser: payload.user,
        tokenExpiry: payload.tokenExpiry,
      };
    }

    default:
      return state;
  }
}
