import { createAction, props } from '@ngrx/store';
import { PanelModel } from '../backend/panels/model/panel.model';

export const RequestPanelsByTapeAction = createAction(
  '[Panels] Request By Tape',
  props<{ payload: { tape: string } }>()
);

export const RequestPanelsByProjectIdsAction = createAction(
  '[Panels] Request By Project Ids',
  props<{ payload: { projectIds: string[] } }>()
);

export const RequestPanelsByCrateAction = createAction(
  '[Panels] Request By Crate',
  props<{ payload: { crateId: string } }>()
);

export const ReceivePanelsAction = createAction(
  '[Panels] Receive',
  props<{ payload: { panels: PanelModel[] } }>()
);

export const RequestPanelByIdAction = createAction(
  '[Panels] Request By Id',
  props<{ payload: { panelId: string } }>()
);

export const ReceivePanelByIdAction = createAction(
  '[Panels] Receive By Id',
  props<{ payload: { panel: PanelModel } }>()
);

export const RequestPanelsUpdateAction = createAction(
  '[Panels] Update',
  props<{ payload: { panels: PanelModel[] } }>()
);

export const PanelPrintRequestAction = createAction(
  '[Panels] Print Request',
  props<{
    payload: {
      panelData: {
        panelId: number;
        workOrderNumber: string;
        panelTag: string;
        panelProject: string;
        panelRelease: string;
        panelQuantity: string;
        panelColor: string;
      };
    };
  }>()
);

export const PanelsPrintRequestAction = createAction(
  '[Panels] Print All Request',
  props<{
    payload: {
      panelsData: {
        panelId: number;
        workOrderNumber: string;
        panelTag: string;
        panelProject: string;
        panelRelease: string;
        panelQuantity: string;
        panelColor: string;
      }[];
    };
  }>()
);

export const PanelPrintReceiveAction = createAction('[Panels] Print Receive');

export const PanelStatusUpdateRequestAction = createAction(
  '[Panels] Status Update Request',
  props<{
    payload: {
      panelData: {
        SFMPanelID: number;
        StatusId: number;
        Project: string;
        EmployeeId: string;
      };
    };
  }>()
);

export const PanelStatusUpdateReceiveAction = createAction(
  '[Panels] Status Update Receive',
  props<{ payload: any }>()
);

export const PanelStatusRequestAction = createAction(
  '[Panels] Status Request',
  props<{ payload: { projectIds: string[] } }>()
);

export const PanelStatusReceiveAction = createAction(
  '[Panels] Status Receive',
  props<{ payload: any }>()
);
