import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { StationModel } from '../../core/backend/stations/model/station.model';
import { RoleModel } from '../../core/backend/auth/model/role.model';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent implements OnInit {
  role: RoleModel;
  roleForm: FormGroup;
  workflowStations: StationModel[] = [];
  staticStations: StationModel[] = [];
  permissions: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { role: RoleModel; stations: StationModel[] },
    public dialogRef: MatDialogRef<RoleComponent>,
    private formBuilder: FormBuilder
  ) {
    if (data) {
      console.log(data);
      this.role = data.role;
      this.workflowStations = data.stations.filter(
        (station) => station.Type === 0
      );
      this.staticStations = data.stations.filter(
        (station) => station.Type === 1
      );
    }

    this.permissions = this.role?.StationAccess.split(',');
    if (this.role?.DesktopAccess) {
      this.permissions.push('0');
    }
    if (this.role?.MobileAccess) {
      this.permissions.push('1');
    }

    this.roleForm = new FormGroup({
      Id: new FormControl(this.role?.Id ? this.role.Id : null),
      Name: new FormControl(this.role?.Name ? this.role.Name : ''),
      StationAccess: new FormControl(
        this.role?.StationAccess ? this.role.StationAccess : ''
      ),
      DesktopAccess: new FormControl(
        this.role?.DesktopAccess ? this.role.DesktopAccess : false
      ),
      MobileAccess: new FormControl(
        this.role?.MobileAccess ? this.role.MobileAccess : false
      ),
      Active: new FormControl(this.role?.Active ? this.role.Active : true),
    });
  }

  ngOnInit() {}

  toggleAccess(e: any, stationId: string) {
    const tempPermissions = Object.assign([], this.permissions);
    if (e.detail.checked) {
      this.permissions = [...new Set([...tempPermissions, stationId])];
    } else {
      this.permissions = tempPermissions.filter((id) => id !== stationId);
    }
  }

  saveChanges() {
    this.roleForm
      .get('StationAccess')
      .setValue(
        this.permissions.filter((id) => id !== '0' && id !== '1').toString()
      );
    this.roleForm
      .get('DesktopAccess')
      .setValue(this.permissions.filter((id) => id === '0').length > 0);
    this.roleForm
      .get('MobileAccess')
      .setValue(this.permissions.filter((id) => id === '1').length > 0);
    this.dialogRef.close(this.roleForm.value);
    this.roleForm.reset();
  }
}
