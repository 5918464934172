import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as TapesActions from './tapes.actions';
import { AppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as TapesSelectors from './tapes.selectors';
import { TapeModel } from '../backend/tapes/model/tape.model';

@Injectable()
export class TapesStoreService {
  constructor(private store: Store<AppState>) {}

  public requestTapesByProject(project: string) {
    this.store.dispatch(
      TapesActions.RequestTapesByProjectAction({ payload: { project } })
    );
  }

  public requestTapes() {
    this.store.dispatch(TapesActions.RequestTapesAction());
  }

  public getAllTapes(): Observable<TapeModel[]> {
    return this.store.pipe(
      select((state) => TapesSelectors.getAllTapes(state))
    );
  }
}
