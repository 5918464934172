import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import * as CratesActions from './crates.actions';
import { PanelsService } from '../backend/panels/panels.service';
import { StationsService } from '../backend/stations/stations.service';
import { PendingStoreService } from '../pending/pending.service';
import { CratesService } from '../backend/crates/crates.service';

@Injectable()
export class CratesEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private pendingStoreService: PendingStoreService,
    private cratesService: CratesService
  ) {}

  @Effect()
  OnUpsertCrates = this.actions$.pipe(
    ofType(CratesActions.UpdateCratesRequestAction),
    map((action) => action.payload.crates),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.cratesService.updateCrates(requestData)).pipe(
        mergeMap((data: any) => [
          CratesActions.CratesReceiveAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestCrates$ = this.actions$.pipe(
    ofType(CratesActions.RequestCratesAction),
    throttleTime(2000),
    tap(() => this.pendingStoreService.enablePendingStatus()),
    switchMap(() =>
      from(this.cratesService.getCrates()).pipe(
        tap(() => this.pendingStoreService.disablePendingStatus()),
        mergeMap((data: any) => [
          CratesActions.CratesReceiveAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestAddPanel$ = this.actions$.pipe(
    ofType(CratesActions.AddPanelRequestAction),
    map((action) => action.payload.crateData),
    throttleTime(2000),
    tap(() => this.pendingStoreService.enablePendingStatus()),
    switchMap((requestData) =>
      from(this.cratesService.addPanelToCrate(requestData)).pipe(
        tap(() => this.pendingStoreService.disablePendingStatus()),
        mergeMap((data: any) => [CratesActions.AddPanelReceiveAction()]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestCratePanels$ = this.actions$.pipe(
    ofType(CratesActions.RequestCratePanelsAction),
    map((action) => action.payload.projectIds),
    throttleTime(2000),
    tap(() => this.pendingStoreService.enablePendingStatus()),
    switchMap((requestData) =>
      from(this.cratesService.getCratePanels(requestData)).pipe(
        tap(() => this.pendingStoreService.disablePendingStatus()),
        mergeMap((data: any) => [
          CratesActions.ReceiveCratePanelsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRemoveCratePanels$ = this.actions$.pipe(
    ofType(CratesActions.RemovePanelRequestAction),
    map((action) => action.payload.id),
    throttleTime(2000),
    tap(() => this.pendingStoreService.enablePendingStatus()),
    switchMap((requestData) =>
      from(this.cratesService.removePanelFromCrate(requestData)).pipe(
        tap(() => this.pendingStoreService.disablePendingStatus()),
        mergeMap((data: any) => [
          CratesActions.RemovePanelReceiveAction({
            payload: { SFMPanelId: requestData },
          }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestMiscItems$ = this.actions$.pipe(
    ofType(CratesActions.RequestMiscItemsAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.cratesService.getMiscItems()).pipe(
        mergeMap((data: any) => [
          CratesActions.ReceiveMiscItemsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestCrateItems$ = this.actions$.pipe(
    ofType(CratesActions.RequestCrateItemsActions),
    map((action) => action.payload.projectIds),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.cratesService.getCrateItems(requestData)).pipe(
        mergeMap((data: any) => [
          CratesActions.ReceiveCrateItemsActions({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  OnRequestAddMiscItem$ = this.actions$.pipe(
    ofType(CratesActions.RequestAddUpdateMiscItemAction),
    map((action) => action.payload.item),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.cratesService.addMiscItem(requestData)).pipe(
        mergeMap((data: any) => [
          CratesActions.ReceiveMiscItemsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  OnRequestAddItemToCrate$ = this.actions$.pipe(
    ofType(CratesActions.RequestAddItemToCrateAction),
    map((action) => action.payload.item),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.cratesService.addItemToCrate(requestData)).pipe(
        mergeMap((data: any) => [
          CratesActions.ReceiveCrateItemsActions({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect({})
  OnRequestRemoveCrateItem$ = this.actions$.pipe(
    ofType(CratesActions.RemoveItemRequestAction),
    map((action) => action.payload.id),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.cratesService.removeItemFromCrate(requestData)).pipe(
        mergeMap((data: any) => [
          CratesActions.RemoveItemReceiveAction({
            payload: requestData as any,
          }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );
}
