import { HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
// import { ErrorMessage } from './model/error-message';

export class BaseService {
  constructor() {}
  // Can't use this since API response codes are not properly implemented, needs to be refactored
  // protected handleErrorResponse(
  //   err: HttpErrorResponse
  // ): Observable<ErrorMessage> {
  //   const { error } = err;
  //
  //   if (error.hasOwnProperty('key')) {
  //     return observableThrowError({
  //       key: error.key,
  //       message: error.message,
  //       status: err.status,
  //     });
  //   } else {
  //     return observableThrowError({
  //       key: error.status,
  //       message: error.message,
  //       status: err.status,
  //     });
  //   }
  // }
}
