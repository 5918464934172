import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AnimationController,
  LoadingController,
  MenuController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { StationsStoreService } from './core/stations/stations.service';
import { StationModel } from './core/backend/stations/model/station.model';
import { Subject } from 'rxjs';
import { PendingStoreService } from './core/pending/pending.service';
import { AuthStoreService } from './core/auth/auth.service';
import { ErrorStoreService } from './core/error/error.service';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  ngUnsubscribe$: Subject<any> = new Subject<any>();
  mobileDesignPlatforms: ['mobile', 'mobileweb'];
  deskotopDesignPlatforms: ['desktop'];
  isMenuOpen = true;
  currentRoute: string;
  workflowStations: StationModel[] = [];
  staticStations: StationModel[] = [];
  // 0 = workflowStations, 1 = static stations
  activeItem: { type: number; id: number; staticUrl: string };
  isDemo = false;

  constructor(
    public platform: Platform,
    private menu: MenuController,
    private router: Router,
    private stationsStoreService: StationsStoreService,
    private pendingStoreService: PendingStoreService,
    public loadingController: LoadingController,
    private authStoreService: AuthStoreService,
    private errorStoreService: ErrorStoreService,
    public toastController: ToastController,
    private animationCtrl: AnimationController
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
        this.isMenuOpen =
          this.currentRoute !== '/' &&
          this.platform.platforms().includes('desktop');
        // this.isMenuOpen = this.platform.platforms().includes('desktop');
        // console.log(event);
      });
  }

  ngOnInit() {
    this.isDemo = environment.apiUrl.includes('demo');
    this.errorHandler();
    this.activeItem = {
      type: null,
      id: null,
      staticUrl: '/dashboard',
    };
    this.menu.enable(true, 'main');
    this.menu.close('main');
    this.stationsStoreService
      .getAllStations()
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((stations) => {
        this.workflowStations = stations.filter(
          (station) => station.Type === 0
        );
        if (this.workflowStations.length > 0) {
          // this.activeItem = {
          //   type: this.workflowStations[0].Type,
          //   id: this.workflowStations[0].Id,
          //   staticUrl: null
          // };
        }
        this.staticStations = stations.filter((station) => station.Type === 1);
      });
    this.stationsStoreService.requestStations();
    this.pendingHandler();
    // console.log(this.platform.platforms());
  }

  minimizeSideNav() {
    const animation: any = this.animationCtrl.create()
        .addElement(document.querySelector('#split-pane'))
        .duration(500)
        .iterations(1)
        .fromTo('width', '180px', '100px');
    animation.play();
  }

  pendingHandler() {
    let loader;
    this.pendingStoreService
      .getPendingStatus()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((status) => {
        console.log(status);
        // if (status){
        //   loader = this.presentLoading();
        //   loader.present();
        // } else {
        //   if (loader) {
        //     loader.dismiss();
        //   }
        // }
      });
  }

  errorHandler() {
    this.errorStoreService
      .getLatestError()
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((error) => {
        this.presentErrorToast(error.Message);
      });
  }

  async presentErrorToast(message: string) {
    console.log(message);
    const toast = await this.toastController.create({
      message: `ERROR: ${message}`,
      duration: 2000,
      color: 'danger',
    });
    toast.present();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  selectMenuItem(stationType: number, id: number, staticUrl?: string) {
    this.activeItem = { type: stationType, id, staticUrl };
    console.log(this.activeItem);

    if (id) {
      this.router.navigate([
        `/stations`,
        {
          stationId: id,
        },
      ]);
    }
  }

  shouldShow() {
    return this.isMenuOpen;
  }

  logout() {
    this.authStoreService.logout();
    this.menu.close();
    this.router.navigate(['/']);
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 3000,
    });
    return loading;
    // await loading.present();
    //
    // const { role, data } = await loading.onDidDismiss();
    // console.log('Loading dismissed!');
  }
}
