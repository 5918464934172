import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { StationModel } from './model/station.model';
import { StationStatusModel } from './model/station-status.model';

@Injectable()
export class StationsBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    // 'content-type': 'application/json',
    //  'enctype': 'multipart/form-data',
    // 'Auth-Token':
    //   '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  fetchUpdateStations(stations: StationModel[]): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/stations`;
    return this.http.post<any[]>(url, { stations }, this.getOptions());
  }

  fetchStations(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/stations`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  fetchUpdateStationsStatuses(
    stationsStatuses: StationStatusModel[]
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/stations/statuses`;
    return this.http.post<any[]>(url, { stationsStatuses }, this.getOptions());
  }

  fetchStationsStatuses(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/stations/statuses`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
