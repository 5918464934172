import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import * as UserActions from './user.actions';
import * as ErrorActions from '../../error/error.actions';
import { AuthService } from '../../backend/auth/auth.service';
import { AuthAmplifyService } from '../../backend/auth/auth-amplify.service';
import * as moment from 'moment';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private authAmplifyService: AuthAmplifyService
  ) {}

  @Effect()
  OnUpsertUser = this.actions$.pipe(
    ofType(UserActions.RegistrationInitAction),
    map((action) => action.payload.user),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.authService.updateUser(requestData)).pipe(
        // tap((user) => this.authAmplifyService.signUp(user)),
        mergeMap((data: any) => [
          UserActions.RegistrationReceiveAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestUsers$ = this.actions$.pipe(
    ofType(UserActions.RequestUsersAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.authService.getUsers()).pipe(
        mergeMap((data: any) => [
          UserActions.ReceiveUsersAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnLoginRequest$ = this.actions$.pipe(
    ofType(UserActions.LoginRequestAction),
    map((action) => action.payload.userCredentials),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.authService.login(requestData)).pipe(
        mergeMap((data: any) => [
          UserActions.LoginReceiveAction({ payload: data }),
        ]),
        catchError((error) => {
          return of(
            ErrorActions.SetErrorAction({
              payload: {
                error: {
                  Message: error.statusText,
                  Module: '[Auth]',
                },
              },
            })
          );
        })
      )
    )
  );

  @Effect({ dispatch: false })
  OnLoginReceive$ = this.actions$.pipe(
    ofType(UserActions.LoginReceiveAction),
    map((action) => action.payload),
    tap((response: any) => {
      localStorage.setItem('jwt', response.data.accessToken);
      localStorage.setItem('tokenExpiry', response.data.tokenExpiry);
      localStorage.setItem('userId', response.data.user.Id);
      localStorage.setItem(
        'user',
        `${response.data.user.FirstName} ${response.data.user.LastName}`
      );
    })
  );

  @Effect({ dispatch: false })
  OnLogout$ = this.actions$.pipe(
    ofType(UserActions.LogoutRequestAction),
    tap((response: any) => {
      localStorage.removeItem('jwt');
      localStorage.removeItem('tokenExpiry');
      localStorage.removeItem('userId');
      localStorage.removeItem('user');
    })
  );

  @Effect()
  OnAuthUserRequest$ = this.actions$.pipe(
    ofType(UserActions.AuthUserRequestAction),
    map((action) => action.payload.userId),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.authService.getAuthUser(requestData)).pipe(
        tap((x) => console.log(x)),
        mergeMap((data: any) => [
          UserActions.AuthUserReceiveAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );
}
