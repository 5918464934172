import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TapeModel } from '../backend/tapes/model/tape.model';
import { PanelModel } from '../backend/panels/model/panel.model';
import { StationModel } from '../backend/stations/model/station.model';
import { StationStatusModel } from '../backend/stations/model/station-status.model';

export interface StationsStateInterface extends EntityState<StationModel> {
  // additional entity state properties
  stationsStatuses: StationStatusModel[];
}

export function selectStationId(a: StationModel): string {
  return a.Id.toString();
}

export function sortById(a: StationModel, b: StationModel): number {
  if (a.Sequence > b.Sequence) {
    return 1;
  }

  if (a.Sequence < b.Sequence) {
    return -1;
  }

  return 0;
}

export const adapterStations: EntityAdapter<StationModel> =
  createEntityAdapter<StationModel>({
    selectId: selectStationId,
    sortComparer: sortById,
  });

export const initStationState: StationsStateInterface =
  adapterStations.getInitialState({
    stationsStatuses: null,
  });

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapterStations.getSelectors();
