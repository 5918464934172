import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import * as TapesActions from './tapes.actions';
import { WorkOrdersService } from '../backend/work-orders/work-orders.service';
import { TapesService } from '../backend/tapes/tapes.service';

@Injectable()
export class TapesEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private tapesService: TapesService
  ) {}

  @Effect()
  OnGetTapesByProjectRequest$ = this.actions$.pipe(
    ofType(TapesActions.RequestTapesByProjectAction),
    map((action) => action.payload.project),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.tapesService.getTapesByProject(requestData)).pipe(
        mergeMap((data: any) => [
          TapesActions.ReceiveTapesAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnGetTapesRequest$ = this.actions$.pipe(
    ofType(TapesActions.RequestTapesAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.tapesService.getTapes()).pipe(
        mergeMap((data: any) => [
          TapesActions.ReceiveTapesAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );
}
