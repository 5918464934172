import { NgModule } from '@angular/core';
import { WorkOrdersStoreService } from '../work-orders/work-orders.service';
import { TapesStoreService } from '../tapes/tapes.service';
import { PanelsStoreService } from '../panels/panels.service';
import { StationsStoreService } from '../stations/stations.service';
import { PendingStoreService } from '../pending/pending.service';
import { CratesStoreService } from '../crates/crates.service';
import { AuthStoreService } from '../auth/auth.service';
import { ErrorStoreService } from '../error/error.service';
import {RouterStoreService} from "../router/router.service";

@NgModule({
  declarations: [],
  imports: [],
  providers: [],
})
export class StoreServicesModule {
  static forRoot() {
    return {
      ngModule: StoreServicesModule,
      providers: [
        WorkOrdersStoreService,
        TapesStoreService,
        PanelsStoreService,
        StationsStoreService,
        PendingStoreService,
        CratesStoreService,
        AuthStoreService,
        ErrorStoreService,
          RouterStoreService
      ],
    };
  }
}
