import * as StationsActions from './stations.actions';
import {
  adapterStations,
  initStationState,
  StationsStateInterface,
} from './stations.state';

export function StationsReducer(
  state = initStationState,
  action
): StationsStateInterface {
  switch (action.type) {
    case StationsActions.ReceiveStationsAction.type: {
      const payload = action.payload.data;
      return adapterStations.upsertMany(payload, { ...state });
    }
    case StationsActions.ReceiveStationsStatusesAction.type: {
      const payload = action.payload.data;
      return {
        ...state,
        stationsStatuses: payload,
      };
    }
    default:
      return state;
  }
}
