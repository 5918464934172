import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserModel } from '../../backend/auth/model/user.model';

export interface UserStateInterface extends EntityState<UserModel> {
  // additional entity state properties
  activeUser: UserModel;
  tokenExpiry: string;
}

export function selectUserId(a: UserModel): string {
  return a.Id.toString();
}

export function sortById(a: UserModel, b: UserModel): number {
  if (a.Id > b.Id) {
    return 1;
  }

  if (a.Id < b.Id) {
    return -1;
  }

  return 0;
}

export const adapterUser: EntityAdapter<UserModel> =
  createEntityAdapter<UserModel>({
    selectId: selectUserId,
    sortComparer: sortById,
  });

export const initUserState: UserStateInterface = adapterUser.getInitialState({
  activeUser: null,
  tokenExpiry: null,
});

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapterUser.getSelectors();
