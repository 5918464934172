import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PanelModel } from '../backend/panels/model/panel.model';

export interface PanelsStateInterface extends EntityState<PanelModel> {
  // additional entity state properties
  panelStatuses: {
    Id: number;
    SFMPanelID: number;
    StatusId: number;
    Project: string;
  }[];
}

export function selectPanelId(a: PanelModel): string {
  return a.SFMPanelID;
}

export function sortById(a: PanelModel, b: PanelModel): number {
  if (a.SFMPanelID > b.SFMPanelID) {
    return 1;
  }

  if (a.SFMPanelID < b.SFMPanelID) {
    return -1;
  }

  return 0;
}

export const adapterPanels: EntityAdapter<PanelModel> =
  createEntityAdapter<PanelModel>({
    selectId: selectPanelId,
    sortComparer: sortById,
  });

export const initPanelsstate: PanelsStateInterface =
  adapterPanels.getInitialState({
    panelStatuses: null,
  });

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapterPanels.getSelectors();
