import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import * as PanelsActions from './panels.actions';
import { PanelsService } from '../backend/panels/panels.service';
import * as ErrorActions from '../error/error.actions';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class PanelsEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private panelsService: PanelsService,
    private loadingCtrl: LoadingController
  ) {}

  @Effect()
  OnGetPanelsByTapeRequest$ = this.actions$.pipe(
    ofType(PanelsActions.RequestPanelsByTapeAction),
    map((action) => action.payload.tape),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.getPanelsByTapes(requestData)).pipe(
        mergeMap((data: any) => [
          PanelsActions.ReceivePanelsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnGetPanelsByProjectIdsRequest$ = this.actions$.pipe(
    ofType(PanelsActions.RequestPanelsByProjectIdsAction),
    map((action) => action.payload.projectIds),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.getPanelsByProjectIds(requestData)).pipe(
        mergeMap((data: any) => [
          PanelsActions.ReceivePanelsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnGetPanelsByCrateRequest$ = this.actions$.pipe(
    ofType(PanelsActions.RequestPanelsByCrateAction),
    map((action) => action.payload.crateId),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.getPanelsByCrate(requestData)).pipe(
        mergeMap((data: any) => [
          PanelsActions.ReceivePanelsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnGetPanelByIdRequest$ = this.actions$.pipe(
    ofType(PanelsActions.RequestPanelByIdAction),
    map((action) => action.payload.panelId),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.getPanelById(requestData)).pipe(
        mergeMap((data: any) => [
          PanelsActions.ReceivePanelByIdAction({ payload: data }),
        ]),
        catchError((error) => {
          return of(
            ErrorActions.SetErrorAction({
              payload: {
                error: {
                  Message: error.statusText,
                  Module: '[Panels]',
                },
              },
            })
          );
        })
      )
    )
  );

  @Effect()
  OnPanelsUpdateRequest$ = this.actions$.pipe(
    ofType(PanelsActions.RequestPanelsUpdateAction),
    map((action) => action.payload.panels),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.updatePanels(requestData)).pipe(
        mergeMap((data: any) => [
          PanelsActions.ReceivePanelByIdAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnPrintPanelRequest$ = this.actions$.pipe(
    ofType(PanelsActions.PanelPrintRequestAction),
    map((action) => action.payload.panelData),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.printPanel(requestData)).pipe(
        // tap(() => {
        //   this.loadingCtrl.dismiss();
        // }),
        mergeMap((data: any) => [PanelsActions.PanelPrintReceiveAction()]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnPrintPanelsRequest$ = this.actions$.pipe(
    ofType(PanelsActions.PanelsPrintRequestAction),
    map((action) => action.payload.panelsData),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.printPanels(requestData)).pipe(
        // tap(() => {
        //   this.loadingCtrl.dismiss();
        // }),
        mergeMap((data: any) => [PanelsActions.PanelPrintReceiveAction()]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnUpdatePanelStatusRequest$ = this.actions$.pipe(
    ofType(PanelsActions.PanelStatusUpdateRequestAction),
    map((action) => action.payload.panelData),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.updatePanelStatus(requestData)).pipe(
        mergeMap((data: any) => [
          PanelsActions.PanelStatusUpdateReceiveAction(data),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestPanelsStatusesByProjects = this.actions$.pipe(
    ofType(PanelsActions.PanelStatusRequestAction),
    map((action) => action.payload.projectIds),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.panelsService.getPanelsStatusesByProjects(requestData)).pipe(
        tap((x) => console.log(x)),
        mergeMap((data: any) => [
          PanelsActions.PanelStatusReceiveAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );
}
