import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ErrorActions from './error.actions';
import { AppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as ErrorSelectors from './error.selectors';
import { ErrorModel } from './models/error.model';

@Injectable()
export class ErrorStoreService {
  constructor(private store: Store<AppState>) {}

  public getLatestError(): Observable<ErrorModel> {
    return this.store.pipe(
      select((state) => ErrorSelectors.getLatestError(state))
    );
  }

  public setError(error: ErrorModel) {
    this.store.dispatch(ErrorActions.SetErrorAction({ payload: { error } }));
  }

  public clearError() {
    this.store.dispatch(ErrorActions.ClearErrorAction());
  }
}
