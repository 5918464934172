import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { StationModel } from '../../core/backend/stations/model/station.model';

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss'],
})
export class StationComponent implements OnInit {
  station: StationModel;
  stationForm: FormGroup;
  stationType: number;
  fallbackIcon = 'fa fa-wrench';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { station: StationModel; stationType: number },
    public dialogRef: MatDialogRef<StationComponent>
  ) {
    console.log(data);
    if (data) {
      this.station = data.station;
      this.stationType = data.stationType;
    }

    this.stationForm = new FormGroup({
      Id: new FormControl(this.station?.Id ? this.station.Id : null),
      Name: new FormControl(this.station?.Name ? this.station.Name : ''),
      Icon: new FormControl(this.station?.Icon ? this.station.Icon : ''),
      Sequence: new FormControl(
        this.station?.Sequence ? this.station.Sequence : ''
      ),
      Type: new FormControl(this.stationType),
      Active: new FormControl(
        this.station?.Active ? this.station.Active : true
      ),
      DefaultStatusId: new FormControl(
        this.station?.DefaultStatusId ? this.station.DefaultStatusId : ''
      ),
    });
  }

  ngOnInit() {}

  closeDialog() {
    // this.dialog.closeAll();
  }

  saveChanges() {
    console.log(this.stationForm.value);
    this.dialogRef.close(this.stationForm.value);
  }

  onIconPickerSelect(icon: string): void {
    this.stationForm.patchValue({
      Icon: icon,
    });
  }
}
