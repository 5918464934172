import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CratesBackend } from './crates.backend';
import { CrateModel } from './model/crate.model';
import { CratePanelModel } from './model/crate-panel.model';

@Injectable()
export class CratesService extends BaseService {
  constructor(private cratesBackend: CratesBackend) {
    super();
  }

  updateCrates(stations: CrateModel[]): Observable<any> {
    return this.cratesBackend
      .fetchUpdateCrates(stations)
      .pipe(map((response) => response.body));
  }

  addPanelToCrate(crateData: CratePanelModel): Observable<any> {
    return this.cratesBackend
      .fetchAddCratePanel(crateData)
      .pipe(map((response) => response.body));
  }

  removePanelFromCrate(id: string): Observable<any> {
    return this.cratesBackend
      .fetchRemoveCratePanel(id)
      .pipe(map((response) => response.body));
  }

  getCrates(): Observable<any> {
    return this.cratesBackend
      .fetchCrates()
      .pipe(map((response) => response.body));
  }

  getCratePanels(projectIds: string[]): Observable<any> {
    return this.cratesBackend
      .fetchCratePanels(projectIds)
      .pipe(map((response) => response.body));
  }

  getMiscItems(): Observable<any> {
    return this.cratesBackend
      .fetchMiscItems()
      .pipe(map((response) => response.body));
  }

  addMiscItem(item: any): Observable<any> {
    return this.cratesBackend
      .fetchAddMiscItem(item)
      .pipe(map((response) => response.body));
  }

  addItemToCrate(item: any): Observable<any> {
    return this.cratesBackend
      .fetchAddItemToCrate(item)
      .pipe(map((response) => response.body));
  }

  getCrateItems(projectIds: string[]): Observable<any> {
    return this.cratesBackend
      .fetchCrateItemsByProjectIds(projectIds)
      .pipe(map((response) => response.body));
  }

  removeItemFromCrate(id: string): Observable<any> {
    return this.cratesBackend
      .fetchRemoveCrateItem(id)
      .pipe(map((response) => response.body));
  }
}
