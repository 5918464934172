import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { throwIfAlreadyLoaded } from '../module-import-guard';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
import { WorkOrdersModule } from './work-orders/work-orders.module';
import { TapesModule } from './tapes/tapes.module';
import { PanelsModule } from './panels/panels.module';
import { StationsModule } from './stations/stations.module';
import { CratesModule } from './crates/crates.module';
import { AuthModule } from './auth/auth.module';

@NgModule({
  imports: [
    WorkOrdersModule,
    TapesModule,
    PanelsModule,
    StationsModule,
    CratesModule,
    AuthModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
  ],
})
export class BackendModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: BackendModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'BackendModule');
  }
}
