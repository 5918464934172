import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { UserModel } from '../../core/backend/auth/model/user.model';
import { RoleModel } from '../../core/backend/auth/model/role.model';
import { IonSelect } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-edit-misc-item',
  templateUrl: './add-edit-misc-item.component.html',
  styleUrls: ['./add-edit-misc-item.component.scss'],
})
export class AddEditMiscItemComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  item: any;
  itemForm: FormGroup;
  ngUnsubscribe = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { item: any },
    public dialogRef: MatDialogRef<AddEditMiscItemComponent>
  ) {
    if (data) {
      this.item = data.item;
    }

    this.itemForm = new FormGroup({
      Id: new FormControl(this.item?.Id ? this.item.Id : null),
      Name: new FormControl(this.item?.Name ? this.item.Name : ''),
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeDialog() {
    // this.dialog.closeAll();
  }

  compareWith(o1: UserModel, o2: UserModel) {
    return o1 && o2 ? o1.Id === o2.Id : o1 === o2;
  }

  saveChanges() {
    this.dialogRef.close(this.itemForm.value);
  }
}
