import * as PendingActions from './pending.actions';
import { initPendingState, PendingStateInterface } from './pending.state';

export function PendingReducer(
  state = initPendingState,
  action
): PendingStateInterface {
  switch (action.type) {
    case PendingActions.EnablePendingAction.type: {
      return {
        ...state,
        isPending: true,
      };
    }
    case PendingActions.DisablePendingAction.type: {
      return {
        ...state,
        isPending: false,
      };
    }
    default:
      return state;
  }
}
