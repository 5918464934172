import * as ErrorActions from './error.actions';
import { ErrorStateInterface, initErrorState } from './error.state';

export function ErrorReducer(
  state = initErrorState,
  action
): ErrorStateInterface {
  switch (action.type) {
    case ErrorActions.SetErrorAction.type: {
      const payload = action.payload.error;
      return { ...state, latestError: payload };
    }
    case ErrorActions.ClearErrorAction.type: {
      return { ...state, latestError: null };
    }
    default:
      return state;
  }
}
