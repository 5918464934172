import { createAction, props } from '@ngrx/store';
import { StationModel } from '../backend/stations/model/station.model';
import { StationStatusModel } from '../backend/stations/model/station-status.model';

export const RequestStationsAction = createAction('[Stations] Request All');

export const ReceiveStationsAction = createAction(
  '[Stations] Receive',
  props<{ payload: { stations: StationModel[] } }>()
);

export const UpdateStationsRequestAction = createAction(
  '[Stations] Update',
  props<{ payload: { stations: StationModel[] } }>()
);

export const RequestStationsStatusesAction = createAction(
  '[Stations] Request All Statuses'
);

export const UpdateStationsStatusesRequestAction = createAction(
  '[Stations] Update Statuses',
  props<{ payload: { stationsStatuses: StationStatusModel[] } }>()
);

export const ReceiveStationsStatusesAction = createAction(
  '[Stations] Receive Statuses',
  props<{ payload: { stations: StationStatusModel[] } }>()
);
