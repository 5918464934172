import * as WorkOrdersActions from './work-orders.actions';
import {
  initWorkOrdersState,
  WorkOrdersStateInterface,
  adapterWorkOrders,
} from './work-orders.state';
import * as StationsActions from '../stations/stations.actions';

export function WorkOrdersReducer(
  state = initWorkOrdersState,
  action
): WorkOrdersStateInterface {
  switch (action.type) {
    case WorkOrdersActions.ReceiveAllWorkOrdersAction.type: {
      const payload = action.payload.data;
      // console.log(payload);
      return adapterWorkOrders.upsertMany(payload, { ...state }); // add or update time jobs
    }

    case WorkOrdersActions.ReceiveShippingListsAction.type: {
      const payload = action.payload.data;
      return {
        ...state,
        shippingLists: payload,
      };
    }
    case WorkOrdersActions.AddShippingListReceiveAction.type: {
      const payload = action.payload.data;
      return {
        ...state,
        shippingLists: [...state.shippingLists, payload],
      };
    }
    default:
      return state;
  }
}
