import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PanelsBackend } from './panels.backend';
import { PanelModel } from './model/panel.model';

@Injectable()
export class PanelsService extends BaseService {
  constructor(private panelsBackend: PanelsBackend) {
    super();
  }

  getPanelsByTapes(tape: string): Observable<any> {
    return this.panelsBackend
      .fetchPanelsByTape(tape)
      .pipe(map((response) => response.body));
  }

  getPanelsByProjectIds(projectIds: string[]): Observable<any> {
    return this.panelsBackend
      .fetchPanelsByProjectIds(projectIds)
      .pipe(map((response) => response.body));
  }

  getPanelsByCrate(crateId: string): Observable<any> {
    return this.panelsBackend
      .fetchPanelsByCrate(crateId)
      .pipe(map((response) => response.body));
  }

  getPanelById(panelId: string): Observable<any> {
    console.log(panelId);
    return this.panelsBackend
      .fetchPanelById(panelId)
      .pipe(map((response) => response.body));
  }

  updatePanels(panels: PanelModel[]): Observable<any> {
    return this.panelsBackend
      .fetchUpdatePanels(panels)
      .pipe(map((response) => response.body));
  }

  printPanel(panel: {
    panelId: number;
    workOrderNumber: string;
    panelTag: string;
    panelProject: string;
    panelRelease: string;
    panelQuantity: string;
    panelColor: string;
  }): Observable<any> {
    return this.panelsBackend
      .fetchPrintPanel(panel)
      .pipe(map((response) => response.body));
  }

  printPanels(
    panels: {
      panelId: number;
      workOrderNumber: string;
      panelTag: string;
      panelProject: string;
      panelRelease: string;
      panelQuantity: string;
      panelColor: string;
    }[]
  ): Observable<any> {
    return this.panelsBackend
      .fetchPrintPanels(panels)
      .pipe(map((response) => response.body));
  }

  updatePanelStatus(panel: {
    SFMPanelID: number;
    StatusId: number;
    Project: string;
    EmployeeId: string;
  }): Observable<any> {
    return this.panelsBackend
      .fetchUpdatePanelStatus(panel)
      .pipe(map((response) => response.body));
  }

  getPanelsStatusesByProjects(projectIds: string[]): Observable<any> {
    return this.panelsBackend
      .fetchGetPanelStatusesByProjects(projectIds)
      .pipe(map((response) => response.body));
  }
}
