import { AppState } from './store.state';

export const getRouterState = (state: AppState) => state.router;
export const getWorkOrdersState = (state: AppState) => state.workOrders;
export const getTapesState = (state: AppState) => state.tapes;
export const getPanelsState = (state: AppState) => state.panels;
export const getStationsState = (state: AppState) => state.stations;
export const getPendingState = (state: AppState) => state.pending;
export const getCratesState = (state: AppState) => state.crates;
export const getAuthState = (state: AppState) => state.auth;
export const getErrorState = (state: AppState) => state.error;
