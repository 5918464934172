import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { RoleModel } from '../../core/backend/auth/model/role.model';
import { UserModel } from '../../core/backend/auth/model/user.model';
import { AuthStoreService } from '../../core/auth/auth.service';
import { CratesStoreService } from '../../core/crates/crates.service';
import { CrateModel } from '../../core/backend/crates/model/crate.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  isQRBtnVisible = false;
  isQRBtnDisabled = false;
  currentRoute: string;
  stationPermissions: number[] = [];
  roles$;
  activeUser$;
  ngUnsubscribe = new Subject();
  activeStationId;
  crates: CrateModel[] = [];

  ionViewWillEnter() {
    this.cratesStoreService
      .getAllCrates()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((crates) => {
        this.crates = crates;
        console.log(crates);
      });
    this.cratesStoreService.requestAllCrates();
  }

  constructor(
    private router: Router,
    private authStoreService: AuthStoreService,
    private cratesStoreService: CratesStoreService
  ) {
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        this.activeStationId = localStorage.getItem('activeStationId');
        // console.log(this.activeStationId);
        this.currentRoute = event.url;

        this.isQRBtnVisible =
          this.currentRoute !== '/dashboard/mobile' &&
          this.currentRoute !== '/crates' &&
          this.currentRoute !== '/crates/add-edit' &&
          this.currentRoute !== '/crates/scan' &&
          this.currentRoute !== '/scan' &&
          !this.currentRoute.includes('scan-result') &&
          !this.currentRoute.includes('manual-input');

        // Handle visibility for shipping station
        // console.log(this.currentRoute);
        if (this.activeStationId === '13') {
          // console.log('ship it to me');
          if (
            this.currentRoute === '/work-orders' ||
            this.currentRoute === '/panels/panels-by-category' ||
            this.currentRoute === '/panels/unassigned-panels'
          ) {
            this.isQRBtnVisible = false;
          }

          // const shipLocation = localStorage.getItem('shipLocation');
          // // Disable scanner logic
          // if (this.crates.length > 0 && shipLocation) {
          //   console.log(this.crates);
          //   this.isQRBtnDisabled = this.crates.filter(c => c.ShipLocation.toString() === shipLocation).length === 0;
          //   console.log(this.isQRBtnDisabled);
          // }
        }
        if (this.currentRoute === '/dashboard/mobile') {
          localStorage.removeItem('activeStationId');
        }
      });
  }

  ngOnInit() {
    this.rolesHandler();
    this.activeUserHandler();
    this.controllerHandler();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  scan() {
    // if (this.currentRoute.includes('crateId') || this.currentRoute.includes('crates-by-category')) {
    if (this.activeStationId === '13') {
      // const crateId = this.currentRoute.replace('/crates/crates-panels;crateId=', '');
      // localStorage.setItem('crate', crateId);
      this.router.navigate(['/crates/scan']);
    } else {
      this.router.navigate(['/scan']);
    }
  }

  controllerHandler() {
    const combinedStreams$ = combineLatest(this.roles$, this.activeUser$);

    combinedStreams$
      .pipe(
        // take(1),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data: any) => {
        this.processPermissions(data[0], data[1]);
      });
  }

  rolesHandler() {
    this.roles$ = this.authStoreService
      .getAllRoles()
      .pipe(filter((data) => data?.length > 0));
  }

  activeUserHandler() {
    this.activeUser$ = this.authStoreService
      .getActiveUser()
      .pipe(filter((data) => !!data));
  }

  processPermissions(roles: RoleModel[], activeUser: UserModel) {
    const activeRole = roles?.find(
      (role) => role.Id.toString() === activeUser?.RoleId.toString()
    );
    this.stationPermissions = activeRole?.StationAccess.split(',').map((id) =>
      Number(id)
    );
    console.log(this.stationPermissions);
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }
}
