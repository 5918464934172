import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { UserModel } from '../../core/backend/auth/model/user.model';
import { RoleModel } from '../../core/backend/auth/model/role.model';
import { IonSelect, LoadingController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { WorkOrderModel } from '../../core/backend/work-orders/model/work-order.model';
import { PanelModel } from '../../core/backend/panels/model/panel.model';
import { StationModel } from '../../core/backend/stations/model/station.model';
import { StationStatusModel } from '../../core/backend/stations/model/station-status.model';
import { CrateModel } from '../../core/backend/crates/model/crate.model';
import { CratePanelModel } from '../../core/backend/crates/model/crate-panel.model';
import { TapeModel } from '../../core/backend/tapes/model/tape.model';
import { WorkOrdersStoreService } from '../../core/work-orders/work-orders.service';
import { PanelsStoreService } from '../../core/panels/panels.service';
import { TapesStoreService } from '../../core/tapes/tapes.service';
import { ActivatedRoute } from '@angular/router';
import { StationsStoreService } from '../../core/stations/stations.service';
import { CratesStoreService } from '../../core/crates/crates.service';
import { AuthStoreService } from '../../core/auth/auth.service';
import * as moment from 'moment';
import { WorkOrdersService } from '../../core/backend/work-orders/work-orders.service';
import { take } from 'rxjs/internal/operators';

@Component({
  selector: 'app-generate-shipping-list',
  templateUrl: './generate-shipping-list.component.html',
  styleUrls: ['./generate-shipping-list.component.scss'],
})
export class GenerateShippingListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  project: string;
  shippingListForm: FormGroup;
  ngUnsubscribe$ = new Subject();
  toppings = new FormControl();
  selectedWorkOrder: any;

  releasesList: string[] = ['No releases found.'];
  paintLocationActive = false;

  workOrders$$;
  panels$;
  stations$;
  panelsStatuses$;
  stationsStatuses$;
  crates$;
  cratePanels$;
  users;
  roles;

  workOrders: any[] = [];
  workOrders$: BehaviorSubject<WorkOrderModel[]> = new BehaviorSubject<
    WorkOrderModel[]
  >([]);
  stations: StationModel[] = [];
  selectedStation: StationModel;
  panels: PanelModel[] = [];
  crates: CrateModel[] = [];
  cratePanels: CratePanelModel[] = [];
  tapes: TapeModel[] = [];
  panelsStatuses: any[] = [];
  stationsStatuses: StationStatusModel[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { Project: string },
    public dialogRef: MatDialogRef<GenerateShippingListComponent>,
    private workOrdersStoreService: WorkOrdersStoreService,
    private loadingCtrl: LoadingController,
    private panelStoreService: PanelsStoreService,
    private tapesStoreService: TapesStoreService,
    private activeRoute: ActivatedRoute,
    private stationsStoreService: StationsStoreService,
    private cratesStoreService: CratesStoreService
  ) {
    console.log(data);
    if (data) {
      this.project = data.Project;
    }

    // this.userForm = new FormGroup({
    //   Id: new FormControl(this.user?.Id ? this.user.Id : null),
    //   FirstName: new FormControl(this.user?.FirstName ? this.user.FirstName : ''),
    //   LastName: new FormControl(this.user?.LastName ? this.user.LastName : ''),
    //   Username: new FormControl(this.user?.Username ? this.user.Username : ''),
    //   Password: new FormControl(data.user ? '' : 'Kanalco@123'),
    //   RoleId: new FormControl(this.user?.RoleId ? this.user.RoleId : ''),
    //   Active: new FormControl(this.user?.Active ? this.user.Active : true),
    // });
  }

  ngOnInit() {
    this.workOrdersHandler();
    this.stationsHandler();
    this.panelsHandler();
    this.panelsStatusesHandler();
    this.stationsStatusesHandler();
    this.cratesHandler();
    this.cratePanelsHandler();
    this.controllerHandler();
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  closeDialog() {
    // this.dialog.closeAll();
  }

  compareWith(o1: UserModel, o2: UserModel) {
    return o1 && o2 ? o1.Id === o2.Id : o1 === o2;
  }

  controllerHandler() {
    const combinedStreams$ = combineLatest(
      this.workOrders$$,
      this.panels$,
      this.stations$,
      this.panelsStatuses$,
      this.stationsStatuses$,
      this.crates$,
      this.cratePanels$
    );

    combinedStreams$
      .pipe(takeUntil(this.ngUnsubscribe$), take(1))
      .subscribe((data: any) => {
        this.processProjects(
          data[0],
          data[1],
          data[2],
          data[3],
          data[4],
          data[5],
          data[6]
        );
      });
  }

  processProjects(
    workOrders: WorkOrderModel[],
    panels: PanelModel[],
    stations: StationModel[],
    panelsStatuses: any[],
    stationsStatuses: StationStatusModel[],
    crates: CrateModel[],
    cratePanels: CratePanelModel[]
  ) {
    console.log('working');
    this.panelsStatuses = panelsStatuses;
    this.workOrders = workOrders;
    this.panels = panels;
    this.crates = crates;
    this.cratePanels = cratePanels;
    this.stationsStatuses = stationsStatuses;
    this.processCrates();
  }

  processCrates() {
    if (this.panels.length > 0) {
      this.releasesList = [
        ...new Set(
          this.panels
            .filter((panel) => panel.Project === this.project)
            .map((p) => p.PhaseNo)
        ),
      ];
    }
    // const filteredPanelStatuses = [];
    // const panelStatusesPanelIds = [...new Set(this.panelsStatuses.map(p => p.SFMPanelID))];
    // panelStatusesPanelIds.forEach(pId => {
    //   const statuses = this.panelsStatuses.filter(status => status.SFMPanelID === pId);
    //   const moments = statuses.length > 0 ? statuses.map(status => status.createdAt).map(d => moment(d)) : [];
    //   const maxDate = moments.length > 0 ? moment.max(moments) : null;
    //   const latestStatus = statuses.find(status => moment(status.createdAt).format('YYYY/MM/DD hh:mm:ss') === maxDate.format('YYYY/MM/DD hh:mm:ss'));
    //   filteredPanelStatuses.push(latestStatus);
    // });

    // const formattedWorkOrders = [];
    // this.workOrders.forEach(workOrder => {
    //
    //   // CRATE CALCULATIONS START
    //
    //   const workOrderCrateIds = [...new Set(this.cratePanels?.filter(cp => cp.Project === workOrder.Project).map(cp => cp.CrateId))];
    //   const openCrates = [];
    //   const closedCrates = [];
    //
    //   for (const crate of this.crates) {
    //     if (workOrderCrateIds.includes(crate.Id)) {
    //       crate.IsOpen ? openCrates.push(crate) : closedCrates.push(crate);
    //     }
    //   }
    //
    //   // CRATE CALCULATIONS END
    //
    //   // PANEL CALCULATIONS START
    //   const latestPanelStatuses = filteredPanelStatuses.filter(ps => ps.Project === workOrder.Project);
    //   const workOrderPanels = this.panels.filter(panel => panel.Project === workOrder.Project);
    //   const shippedPanelIds = latestPanelStatuses.filter(p => p.StatusId === 20).map(p => p.SFMPanelID); // 20 is shipped status
    //
    //   const pendingPanels = workOrderPanels.filter(panel => !shippedPanelIds.includes(panel.SFMPanelID));
    //   const shippedPanels = workOrderPanels.filter(panel => shippedPanelIds.includes(panel.SFMPanelID));
    //
    //   formattedWorkOrders.push({...workOrder, ...{
    //       OpenCrates: openCrates,
    //       ClosedCrates: closedCrates,
    //       CratesRatio: `${openCrates.length}/${closedCrates.length}`,
    //       PendingPanels: pendingPanels,
    //       ShippedPanels: shippedPanels,
    //       PanelsRatio: `${shippedPanels.length}/${workOrderPanels.length}`
    //     }});
    // });
    // console.log(formattedWorkOrders);

    this.selectedWorkOrder = this.workOrders.find(
      (wo) => wo.Project === this.project
    );

    console.log(this.selectedWorkOrder);
    // this.shippingListForm.patchValue({
    //   firstName: 'Nancy',
    //   address: {
    //     street: '123 Drew Street'
    //   }
    // });
    // this.items$.next(formattedWorkOrders);
    const projectPanels = this.panels.filter((p) => p.Project === this.project);
    const colors = projectPanels.map((p) => p.MaterialDescription);
    const uniqueColors = [...new Set(colors)];
    this.paintLocationActive =
      projectPanels.filter((p) => p.MaterialItemNo === 'AL/Plate').length > 0;

    const customerProjectSplit = this.selectedWorkOrder.Address.indexOf(',');
    const customerProject = this.selectedWorkOrder.Address.substring(
      0,
      customerProjectSplit
    );

    const customerAddress = this.selectedWorkOrder.Address.substring(
      customerProjectSplit + 1
    );

    this.shippingListForm = new FormGroup({
      Id: new FormControl(''),
      Project: new FormControl(this.selectedWorkOrder.Project),
      WOProject: new FormControl(this.selectedWorkOrder.Customer),
      CustomerProject: new FormControl(customerProject),
      Releases: new FormControl(''),
      Colors: new FormControl(
        uniqueColors?.length > 0 ? uniqueColors.join(',') : ''
      ),
      ShippingAddress: new FormControl(customerAddress),
      Notes: new FormControl(''),
      ShippingDate: new FormControl(moment().format('YYYY-MM-DD')),
      ShipLocation: new FormControl(''),
      CrateIds: new FormControl(''),
      PanelIds: new FormControl(''),
    });
  }

  private workOrdersHandler() {
    this.workOrders$$ = this.workOrdersStoreService
      .getAllWorkOrders()
      .pipe(filter((data: any) => data?.length > 0));
    // this.workOrders$$.pipe(
    //   filter((data: any) => data?.length > 0),
    //   takeUntil(this.ngUnsubscribe$)
    // ).subscribe(data => {
    //   // console.log(data);
    //   this.cratesStoreService.requestAllCratePanels(data.map(order => order.Project));
    //   this.panelStoreService.requestPanelsByProjectIds(data.map(order => order.Project));
    //   this.panelStoreService.requestPanelsStatusesByProjectIds(data.map(order => order.Project));
    // });
  }

  private panelsStatusesHandler() {
    this.panelsStatuses$ = this.panelStoreService.getAllPanelStatuses().pipe();
    // filter((data: any) => data?.length > 0));
  }

  private panelsHandler() {
    this.panels$ = this.panelStoreService.getAllPanels().pipe();
    // filter((data: any) => data?.length > 0));
  }

  private cratePanelsHandler() {
    this.cratePanels$ = this.cratesStoreService.getAllCratePanels().pipe();
    // this.cratePanels$.pipe(
    //   takeUntil(this.ngUnsubscribe$)
    // ).subscribe(data => this.cratePanelsDetails$.next(data));
  }

  private cratesHandler() {
    this.crates$ = this.cratesStoreService.getAllCrates().pipe();
    // filter((data: any) => data?.length > 0));
  }

  private stationsHandler() {
    this.stations$ = this.stationsStoreService.getAllStations().pipe();
    // filter((data: any) => data?.length > 0));
    // this.stations$.pipe(
    //   takeUntil(this.ngUnsubscribe$)
    // ).subscribe(data => {
    //   this.stations = Object.assign([], ...data);
    //   this.selectedStation = data.find(station => station.Id === this.stationId);
    // });
  }

  private stationsStatusesHandler() {
    this.stationsStatuses$ = this.stationsStoreService
      .getAllStationsStatuses()
      .pipe();
    // filter((data: any) => data?.length > 0));
  }

  saveChanges() {
    // this.shippingListForm.patchValue({
    //   Releases: this.shippingListForm.get('Releases').value.toString(),
    //   // formControlName2: myValue2 (can be omitted)
    // });
    console.log(this.shippingListForm.value);

    const crates = this.crates
      .filter(
        (crate) =>
          crate.Project === this.project &&
          crate.ShipLocation.toString() ===
            this.shippingListForm.get('ShipLocation').value.toString()
      )
      .map((c) => c.Id);

    // console.log(crates);
    const cratePanels = this.cratePanels.filter((cp) =>
      crates.includes(cp.CrateId)
    );
    // console.log(cratePanels);

    const filteredPanelIds = [];
    for (const cratePanel of cratePanels) {
      const selectedPanel = this.panels.find(
        (panel) =>
          panel.SFMPanelID.toString() === cratePanel.SFMPanelID.toString()
      );
      // console.log(this.shippingListForm.get('Releases').value);
      // console.log(selectedPanel.PhaseNo);
      if (
        this.shippingListForm.get('Releases').value.length > 0 &&
        this.shippingListForm
          .get('Releases')
          .value.includes(selectedPanel.PhaseNo)
      ) {
        filteredPanelIds.push(selectedPanel.SFMPanelID);
      }
    }

    this.workOrdersStoreService.addShippingList({
      ...this.shippingListForm.value,
      ...{
        Releases: this.shippingListForm.get('Releases').value.toString(),
        CrateIds: crates.toString(),
        // PanelIds: filteredPanelIds.toString(),
        PanelIds: 'test',
      },
    });
    this.dialogRef.close();
  }
}
