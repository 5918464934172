import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PanelModel } from './model/panel.model';

@Injectable()
export class PanelsBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    // 'content-type': 'application/json',
    //  'enctype': 'multipart/form-data',
    // 'Auth-Token':
    //   '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  fetchPanelsByTape(tape: string): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels/byTape`;
    return this.http.post<any[]>(url, { tapeNumber: tape }, this.getOptions());
  }

  fetchPanelsByProjectIds(projectIds: string[]): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels/byProjects`;
    return this.http.post<any[]>(url, { projectIds }, this.getOptions());
  }

  fetchPanelsByCrate(crateId: string): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels/byCrate`;
    return this.http.post<any[]>(url, { crateId }, this.getOptions());
  }

  fetchPanelById(id: string): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels/${id}`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  fetchUpdatePanels(panels: PanelModel[]): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels`;
    return this.http.post<any[]>(url, { panels }, this.getOptions());
  }

  fetchPrintPanel(panel: {
    panelId: number;
    workOrderNumber: string;
    panelTag: string;
    panelProject: string;
    panelRelease: string;
    panelQuantity: string;
    panelColor: string;
  }): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels/print`;
    return this.http.post<any[]>(url, { panelData: panel }, this.getOptions());
  }

  fetchPrintPanels(
    panels: {
      panelId: number;
      workOrderNumber: string;
      panelTag: string;
      panelProject: string;
      panelRelease: string;
      panelQuantity: string;
      panelColor: string;
    }[]
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels/print-tape`;
    return this.http.post<any[]>(
      url,
      { panelsData: panels },
      this.getOptions()
    );
  }

  fetchUpdatePanelStatus(panel: {
    SFMPanelID: number;
    StatusId: number;
    Project: string;
    EmployeeId: string;
  }): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels/status`;
    return this.http.post<any[]>(url, { panelData: panel }, this.getOptions());
  }

  fetchGetPanelStatusesByProjects(
    projectIds: string[]
  ): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/panels/statusByProjects`;
    return this.http.post<any[]>(url, { projectIds }, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
