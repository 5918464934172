import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as PendingActions from './pending.actions';
import { AppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as PendingSelectors from './pending.selectors';

@Injectable()
export class PendingStoreService {
  constructor(private store: Store<AppState>) {}

  public enablePendingStatus() {
    this.store.dispatch(PendingActions.EnablePendingAction());
  }

  public disablePendingStatus() {
    this.store.dispatch(PendingActions.DisablePendingAction());
  }

  public getPendingStatus(): Observable<boolean> {
    return this.store.pipe(
      select((state) => PendingSelectors.getPendingStatus(state))
    );
  }
}
