import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
// import * as AuthSessionSelectors from './auth-session/auth-session.selectors';
import * as RoleActions from './roles/roles.actions';
import * as RoleSelectors from './roles/roles.selectors';
import * as UserActions from './user/user.actions';
import * as UserSelectors from './user/user.selectors';
import { Subject } from 'rxjs';
import { AppState } from '../store/store.state';
import { RoleModel } from '../backend/auth/model/role.model';
import { UserModel } from '../backend/auth/model/user.model';

@Injectable()
export class AuthStoreService {
  public loginResolved = new Subject<void>();
  public tempPassChangeRequired = new Subject<{
    user_id: string;
    jwtToken: string;
  }>();

  constructor(private store: Store<AppState>) {}
  public initLogin(userCredentials: { username: string; password: string }) {
    this.store.dispatch(
      UserActions.LoginRequestAction({ payload: { userCredentials } })
    );
  }
  // public initChangeTemporaryPassword(
  //   user_id: string,
  //   password: string,
  //   jwtToken: string
  // ) {
  //   this.store.dispatch(
  //     AuthSessionActions.ChangeTemporaryPasswordRequestAction({
  //       payload: { user_id, password, jwtToken },
  //     })
  //   );
  // }
  public updateRoles(roles: RoleModel[]) {
    this.store.dispatch(
      RoleActions.UpdateRolesRequestAction({ payload: { roles } })
    );
  }

  public updateUser(user: UserModel) {
    this.store.dispatch(
      UserActions.RegistrationInitAction({ payload: { user } })
    );
  }

  public requestRoles() {
    this.store.dispatch(RoleActions.RequestRolesAction());
  }

  public requestUsers() {
    this.store.dispatch(UserActions.RequestUsersAction());
  }

  public requestActiveUser(userId: string) {
    this.store.dispatch(
      UserActions.AuthUserRequestAction({ payload: { userId } })
    );
  }
  // public initPasswordReset(username: string, password: string, year: string) {
  //   this.store.dispatch(
  //       AuthSessionActions.PasswordResetRequestAction({ payload: { username, password, year } })
  //   );
  // }
  public getAllRoles() {
    return this.store.pipe(select((state) => RoleSelectors.getAllRoles(state)));
  }

  public getAllUsers() {
    return this.store.pipe(select((state) => UserSelectors.getAllUsers(state)));
  }

  public getActiveUser() {
    return this.store.pipe(
      select((state) => UserSelectors.getActiveUser(state))
    );
  }
  public logout() {
    this.store.dispatch(UserActions.LogoutRequestAction());
  }
}
