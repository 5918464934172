import { createAction, props } from '@ngrx/store';
import { WorkOrderModel } from '../backend/work-orders/model/work-order.model';

export const RequestAllWorkOrdersAction = createAction(
  '[Work Orders] Request All'
);

export const ReceiveAllWorkOrdersAction = createAction(
  '[Work Orders] Receive All Jobs',
  props<{ payload: { workOrders: WorkOrderModel[] } }>()
);

export const RequestSyncAction = createAction(
  '[Work Orders] Request Sync',
  props<{ payload: { projects: string[] } }>()
);

export const ReceiveSyncAction = createAction(
  '[Work Orders] Receive Sync',
  props<{ payload: { syncStatus: string } }>()
);

export const AddShippingListAction = createAction(
  '[Shipping Lists] Add',
  props<{ payload: { shippingList: any } }>()
);

export const AddShippingListReceiveAction = createAction(
  '[Shipping Lists] Receive',
  props<{ payload: any }>()
);

export const ReceiveShippingListsAction = createAction(
  '[Shipping Lists] Receive All',
  props<{ payload: { shippingLists: any[] } }>()
);

export const RequestShippingListsAction = createAction(
  '[Shipping Lists] Request All'
);
