import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as RouterSelectors from './router.selectors';
import { AppState } from '../store/store.state';
import { Observable } from 'rxjs';

@Injectable()
export class RouterStoreService {
  constructor(private store: Store<AppState>) {}

  public getActiveRoute(): Observable<any> {
    return this.store.pipe(
      select((state: any) => RouterSelectors.getActiveRoute(state))
    );
  }
}
