import { createAction, props } from '@ngrx/store';
import { CrateModel } from '../backend/crates/model/crate.model';
import { CratePanelModel } from '../backend/crates/model/crate-panel.model';

export const RequestCratesAction = createAction('[Crates] Request All');

export const UpdateCratesRequestAction = createAction(
  '[Crates] Update',
  props<{ payload: { crates: CrateModel[] } }>()
);

export const CratesReceiveAction = createAction(
  '[Crates] Receive',
  props<{ payload: { crates: CrateModel[] } }>()
);

export const AddPanelRequestAction = createAction(
  '[Crates] Add Panel Request',
  props<{ payload: { crateData: CratePanelModel } }>()
);

export const AddPanelReceiveAction = createAction('[Crates] Add Panel Receive');

export const RemovePanelRequestAction = createAction(
  '[Crates] Remove Panel Request',
  props<{ payload: { id: string } }>()
);

export const RemovePanelReceiveAction = createAction(
  '[Crates] Remove Panel Receive',
  props<{ payload: { SFMPanelId: string } }>()
);

export const RequestCratePanelsAction = createAction(
  '[Crates] Request Crate Panels',
  props<{ payload: { projectIds: string[] } }>()
);

export const ReceiveCratePanelsAction = createAction(
  '[Crates] Receive Crate Panels',
  props<{ payload: { cratePanels: CratePanelModel[] } }>()
);

export const RequestMiscItemsAction = createAction(
  '[Crates] Request Miscellaneous'
);

export const ReceiveMiscItemsAction = createAction(
  '[Crates] Receive Miscellaneous',
  props<{ payload: { miscItems: any[] } }>()
);

export const RequestAddUpdateMiscItemAction = createAction(
  '[Crates] Add Update Miscellaneous Item',
  props<{ payload: { item: any } }>()
);

export const RequestAddItemToCrateAction = createAction(
  '[Crates] Add Item',
  props<{ payload: { item: any } }>()
);

export const RequestCrateItemsActions = createAction(
  '[Crates] Request Items',
  props<{ payload: { projectIds: string[] } }>()
);

export const ReceiveCrateItemsActions = createAction(
  '[Crates] Receive Items',
  props<{ payload: { items: any } }>()
);

export const RemoveItemRequestAction = createAction(
  '[Crates] Remove Item Request',
  props<{ payload: { id: string } }>()
);

export const RemoveItemReceiveAction = createAction(
  '[Crates] Remove Item Receive',
  props<{ payload: { Id: string } }>()
);
