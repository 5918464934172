import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StationComponent } from './station/station.component';
import { IconPickerModule } from 'ngx-icon-picker';
import { StationStatusComponent } from './station-status/station-status.component';
import { EmployeeAddEditComponent } from './employee-add-edit/employee-add-edit.component';
import { RoleComponent } from './role/role.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { GenerateShippingListComponent } from './generate-shipping-list/generate-shipping-list.component';
import { MatInputModule } from '@angular/material/input';
import { SyncOptionsComponent } from './sync-options/sync-options.component';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AddEditMiscItemComponent } from './add-edit-misc-item/add-edit-misc-item.component';
import {UpdatePanelStatusComponent} from './update-panel-status/update-panel-status.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    IconPickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
  ],
  declarations: [
    StationComponent,
    StationStatusComponent,
    EmployeeAddEditComponent,
    AddEditMiscItemComponent,
    RoleComponent,
    GenerateShippingListComponent,
    SyncOptionsComponent,
    UpdatePanelStatusComponent
  ],
})
export class ModalsModule {}
