import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StationsBackend } from './stations.backend';
import { StationModel } from './model/station.model';
import { StationStatusModel } from './model/station-status.model';

@Injectable()
export class StationsService extends BaseService {
  constructor(private stationsBackend: StationsBackend) {
    super();
  }

  updateStations(stations: StationModel[]): Observable<any> {
    return this.stationsBackend
      .fetchUpdateStations(stations)
      .pipe(map((response) => response.body));
  }

  getStations(): Observable<any> {
    return this.stationsBackend
      .fetchStations()
      .pipe(map((response) => response.body));
  }

  updateStationsStatuses(
    stationsStatuses: StationStatusModel[]
  ): Observable<any> {
    return this.stationsBackend
      .fetchUpdateStationsStatuses(stationsStatuses)
      .pipe(map((response) => response.body));
  }

  getStationsStatuses(): Observable<any> {
    return this.stationsBackend
      .fetchStationsStatuses()
      .pipe(map((response) => response.body));
  }
}
