import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WorkOrderModel } from '../backend/work-orders/model/work-order.model';

export interface WorkOrdersStateInterface extends EntityState<WorkOrderModel> {
  // additional entity state properties
  shippingLists: any[];
}

export function selectUserId(a: WorkOrderModel): string {
  return a.Project;
}

export const adapterWorkOrders: EntityAdapter<WorkOrderModel> =
  createEntityAdapter<WorkOrderModel>({
    selectId: selectUserId,
  });

export const initWorkOrdersState: WorkOrdersStateInterface =
  adapterWorkOrders.getInitialState({
    shippingLists: null,
  });

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapterWorkOrders.getSelectors();
