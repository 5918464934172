import { createAction, props } from '@ngrx/store';
import { RoleModel } from '../../backend/auth/model/role.model';
import { UserModel } from '../../backend/auth/model/user.model';

export const RegistrationInitAction = createAction(
  '[Register] Init',
  props<{ payload: { user: UserModel } }>()
);

export const RegistrationReceiveAction = createAction(
  '[Register] Receive',
  props<{ payload: { user: UserModel } }>()
);

export const RequestUsersAction = createAction('[User] Request All');

export const ReceiveUsersAction = createAction(
  '[User] Receive All',
  props<{ payload: { user: UserModel[] } }>()
);

export const LoginRequestAction = createAction(
  '[Login] Request',
  props<{
    payload: { userCredentials: { username: string; password: string } };
  }>()
);

export const LoginReceiveAction = createAction(
  '[Login] Receive',
  props<{ payload: { activeUser: UserModel } }>()
);

export const AuthUserRequestAction = createAction(
  '[Auth] User Request',
  props<{ payload: { userId: string } }>()
);

export const AuthUserReceiveAction = createAction(
  '[Auth] User Receive',
  props<{ payload: { activeUser: UserModel } }>()
);

export const LogoutRequestAction = createAction('[Auth] Logout');
