import { createSelector } from '@ngrx/store';
import { getCratesState } from '../store/store.selectors';
import { selectAll, CratesStateInterface } from './crates.state';

export const getAllCrates = createSelector(
  getCratesState,
  (state: CratesStateInterface) => selectAll(state)
);

export const getAllCratePanels = createSelector(
  getCratesState,
  (state: CratesStateInterface) => state.cratePanels
);

export const getMiscItems = createSelector(
  getCratesState,
  (state: CratesStateInterface) => state.miscItems
);

export const getCrateItems = createSelector(
  getCratesState,
  (state: CratesStateInterface) => state.crateItems
);
