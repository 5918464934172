import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as WorkOrdersActions from './work-orders.actions';
import { AppState } from '../store/store.state';
import { Observable } from 'rxjs';
import * as WorkOrdersSelectors from './work-orders.selectors';
import { WorkOrderModel } from '../backend/work-orders/model/work-order.model';

@Injectable()
export class WorkOrdersStoreService {
  constructor(private store: Store<AppState>) {}

  public requestWorkOrders() {
    this.store.dispatch(WorkOrdersActions.RequestAllWorkOrdersAction());
  }

  public getAllWorkOrders(): Observable<WorkOrderModel[]> {
    return this.store.pipe(
      select((state) => WorkOrdersSelectors.getAllWorkOrders(state))
    );
  }

  public requestSync(projects: string[]) {
    this.store.dispatch(
      WorkOrdersActions.RequestSyncAction({ payload: { projects } })
    );
  }

  public addShippingList(shippingList: any) {
    this.store.dispatch(
      WorkOrdersActions.AddShippingListAction({ payload: { shippingList } })
    );
  }

  public requestShippingLists() {
    this.store.dispatch(WorkOrdersActions.RequestShippingListsAction());
  }

  public getAllShippingLists(): Observable<any[]> {
    return this.store.pipe(
      select((state) => WorkOrdersSelectors.getAllShippingLists(state))
    );
  }
}
