import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { Router } from '@angular/router';
import * as StationsActions from './stations.actions';
import { PanelsService } from '../backend/panels/panels.service';
import { StationsService } from '../backend/stations/stations.service';
import { PendingStoreService } from '../pending/pending.service';

@Injectable()
export class StationsEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private panelsService: PanelsService,
    private stationsService: StationsService,
    private pendingStoreService: PendingStoreService
  ) {}

  @Effect({ dispatch: false })
  OnUpsertStations = this.actions$.pipe(
    ofType(StationsActions.UpdateStationsRequestAction),
    map((action) => action.payload.stations),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.stationsService.updateStations(requestData)).pipe(
        mergeMap((data: any) => [
          StationsActions.ReceiveStationsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestStations$ = this.actions$.pipe(
    ofType(StationsActions.RequestStationsAction),
    throttleTime(2000),
    tap(() => this.pendingStoreService.enablePendingStatus()),
    switchMap(() =>
      from(this.stationsService.getStations()).pipe(
        tap(() => this.pendingStoreService.disablePendingStatus()),
        mergeMap((data: any) => [
          StationsActions.ReceiveStationsAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  OnUpsertStationsStatuses = this.actions$.pipe(
    ofType(StationsActions.UpdateStationsStatusesRequestAction),
    map((action) => action.payload.stationsStatuses),
    throttleTime(2000),
    switchMap((requestData) =>
      from(this.stationsService.updateStationsStatuses(requestData)).pipe(
        mergeMap((data: any) => [
          StationsActions.ReceiveStationsStatusesAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );

  @Effect()
  OnRequestStationsStatuses$ = this.actions$.pipe(
    ofType(StationsActions.RequestStationsStatusesAction),
    throttleTime(2000),
    switchMap(() =>
      from(this.stationsService.getStationsStatuses()).pipe(
        mergeMap((data: any) => [
          StationsActions.ReceiveStationsStatusesAction({ payload: data }),
        ]),
        catchError((err) => of(console.log(err)))
      )
    )
  );
}
