import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { UserModel } from '../../core/backend/auth/model/user.model';
import { RoleModel } from '../../core/backend/auth/model/role.model';
import { IonSelect } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-employee-add-edit',
  templateUrl: './employee-add-edit.component.html',
  styleUrls: ['./employee-add-edit.component.scss'],
})
export class EmployeeAddEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  user: UserModel;
  userForm: FormGroup;
  roles: RoleModel[];
  ngUnsubscribe = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { user: UserModel; roles: RoleModel[] },
    public dialogRef: MatDialogRef<EmployeeAddEditComponent>
  ) {
    console.log(data);
    if (data) {
      this.user = data.user;
      this.roles = data.roles;
    }

    this.userForm = new FormGroup({
      Id: new FormControl(this.user?.Id ? this.user.Id : null),
      FirstName: new FormControl(
        this.user?.FirstName ? this.user.FirstName : ''
      ),
      LastName: new FormControl(this.user?.LastName ? this.user.LastName : ''),
      Username: new FormControl(this.user?.Username ? this.user.Username : ''),
      Password: new FormControl(data.user ? '' : 'Kanalco@123'),
      RoleId: new FormControl(this.user?.RoleId ? this.user.RoleId : ''),
      Active: new FormControl(this.user?.Active),
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeDialog() {
    // this.dialog.closeAll();
  }

  compareWith(o1: UserModel, o2: UserModel) {
    return o1 && o2 ? o1.Id === o2.Id : o1 === o2;
  }

  saveChanges() {
    console.log(this.userForm.value);
    this.dialogRef.close(this.userForm.value);
  }
}
