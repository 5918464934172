import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'work-orders',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./work-orders/work-orders.module').then(
        (m) => m.WorkOrdersPageModule
      ),
  },
  {
    path: 'tapes',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./tapes/tapes.module').then((m) => m.TapesPageModule),
  },
  {
    path: 'panels',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./panels/panels.module').then((m) => m.PanelsPageModule),
  },
  {
    path: 'qr-scanner',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./qr-scanner/qr-scanner.module').then(
        (m) => m.QrScannerPageModule
      ),
  },
  {
    path: 'scan',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./qr/scan/scan.module').then((m) => m.ScanPageModule),
  },
  {
    path: 'qr-sample',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./qr/sample-qr-code/sample-qr-code.module').then(
        (m) => m.SampleQrCodePageModule
      ),
  },
  {
    path: 'scan-result/:panelId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./qr/scan-result/scan-result.module').then(
        (m) => m.ScanResultPageModule
      ),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'manual-input',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./qr/manual-input/manual-input.module').then(
        (m) => m.ManualInputPageModule
      ),
  },
  {
    path: 'crates',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./crates/crates.module').then((m) => m.CratesPageModule),
  },
  {
    path: 'stations',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./stations/station.module').then((m) => m.StationPageModule),
  },
  {
    path: 'employees',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./employees/employees.module').then((m) => m.EmployeesPageModule),
  },
  {
    path: 'inventory',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./inventory/inventory.module').then((m) => m.InventoryModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
