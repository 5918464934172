import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RoleModel } from '../../backend/auth/model/role.model';

export interface RolesStateInterface extends EntityState<RoleModel> {
  // additional entity state properties
}

export function selectRoleId(a: RoleModel): string {
  return a.Id.toString();
}

export function sortById(a: RoleModel, b: RoleModel): number {
  if (a.Id > b.Id) {
    return 1;
  }

  if (a.Id < b.Id) {
    return -1;
  }

  return 0;
}

export const adapterRoles: EntityAdapter<RoleModel> =
  createEntityAdapter<RoleModel>({
    selectId: selectRoleId,
    sortComparer: sortById,
  });

export const initRoleState: RolesStateInterface = adapterRoles.getInitialState(
  {}
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapterRoles.getSelectors();
