import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Vibration } from '@ionic-native/vibration/ngx';
import { ModalsModule } from './modals/modals.module';
import { SharedModule } from './shared/shared.module';

import Amplify from 'aws-amplify';
import {MatTooltipModule} from '@angular/material/tooltip';
// import awsconfig from '../aws-exports';

/* Configure Amplify resources */
// Amplify.configure(awsconfig);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    QRCodeModule,
    BrowserAnimationsModule,
    ModalsModule,
    SharedModule,
    MatTooltipModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: Window, useValue: window },
    Vibration,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
