import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule as RxStoreModule } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { RouterSerializer } from './store.utils';
import { StoreServicesModule } from './store-services.module';
import { APP_REDUCER_TOKEN, AppReducers, metaReducers } from './store.reducers';
import { WorkOrdersEffects } from '../work-orders/work-orders.effects';
import { TapesEffects } from '../tapes/tapes.effects';
import { PanelsEffects } from '../panels/panels.effects';
import { StationsEffects } from '../stations/stations.effects';
import { CratesEffects } from '../crates/crates.effects';
import { RolesEffects } from '../auth/roles/roles.effects';
import { UserEffects } from '../auth/user/user.effects';

const EFFECTS = [
  WorkOrdersEffects,
  TapesEffects,
  PanelsEffects,
  StationsEffects,
  CratesEffects,
  RolesEffects,
  UserEffects,
];

@NgModule({
  imports: [
    EffectsModule.forRoot(EFFECTS),
    RxStoreModule.forRoot(APP_REDUCER_TOKEN, { metaReducers }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
        })
      : [],
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: RouterSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    StoreServicesModule.forRoot(),
  ],
  providers: [{ provide: APP_REDUCER_TOKEN, useValue: AppReducers }],
})
export class StoreModule {}
