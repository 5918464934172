import { createAction, props } from '@ngrx/store';
import { TapeModel } from '../backend/tapes/model/tape.model';

export const RequestTapesByProjectAction = createAction(
  '[Tapes] Request By Project',
  props<{ payload: { project: string } }>()
);
export const RequestTapesAction = createAction('[Tapes] Request');

export const ReceiveTapesAction = createAction(
  '[Tapes] Receive',
  props<{ payload: { tapes: TapeModel[] } }>()
);
